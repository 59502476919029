import { DateConvert } from "utilities";

export const formInitialValues = (data) => ({
  program: {
    idProgram: data?.id_program ?? undefined,
    namaProgram: data?.nama_program ?? "",
    deskripsiProgram: data?.deskripsi_program ?? "",
    tglProgram: data?.tgl_program ?? null,
    idPenanggungJawabProgram: data?.id_penanggung_jawab_program ?? undefined,
    namaPenanggungJawabProgram: data?.nama_penanggung_jawab_program ?? "",
    idUnitOrganisasiProgram: data?.id_unit_organisasi ?? undefined,
    namaUnitOrganisasiProgram: data?.nama_unit_organisasi ?? "",
    periodeMulaiProgram: data?.periode_mulai_program ?? null,
    periodeSelesaiProgram: data?.periode_selesai_program ?? null,
    tglInputProgram: data?.tgl_input ?? DateConvert(new Date()).default,
    noProgram: data?.no_program ?? "",
    lamaPeriodeProgram: data?.lama_periode_program ?? "",
  },
  kegiatan: {
    deskripsiKegiatan: data?.deskripsi_kegiatan ?? "",
    idPenanggungJawabKegiatan: data?.id_penanggung_jawab_kegiatan ?? null,
    namaPenanggungJawabKegiatan: data?.nama_penanggung_jawab_kegiatan ?? "",
    tglKegiatan: data?.tgl_kegiatan ?? null,
    tglInputKegiatan: data?.tgl_input ?? DateConvert(new Date()).default,
    periodeMulaiKegiatan: data?.periode_mulai_kegiatan ?? null,
    periodeSelesaiKegiatan: data?.periode_selesai_kegiatan ?? null,
    idJenisAnggaran: data?.id_jenis_anggaran ?? null,
    namaJenisAnggaran: data?.nama_jenis_anggaran ?? "",
    idKelompokAnggaran: data?.id_kelompok_anggaran ?? null,
    namaKelompokAnggaran: data?.nama_kelompok_anggaran ?? "",
    idSubKelompokAnggaran: data?.id_sub_kelompok_anggaran ?? null,
    namaSubKelompokAnggaran: data?.nama_sub_kelompok_anggaran ?? "",
    namaKegiatan: data?.nama_kegiatan ?? "",
    noKegiatan: data?.no_kegiatan ?? "",
    idKegiatan: data?.id_kegiatan ?? undefined,
    lamaPeriodeKegiatan: data?.lama_periode_kegiatan ?? "",
  },
  subKegiatan: {
    idSubKegiatan: data?.id_sub_kegiatan ?? undefined,
    tglSubKegiatan: data?.tgl_sub_kegiatan ?? "",
    deskripsiSubKegiatan: data?.deskripsi_sub_kegiatan ?? "",
    noSubKegiatan: data?.no_sub_kegiatan ?? "",
    namaSubKegiatan: data?.nama_sub_kegiatan ?? "",
    tglInputSubKegiatan: data?.tgl_input ?? DateConvert(new Date()).default,
    periodeMulaiSubKegiatan: data?.periode_mulai_sub_kegiatan ?? null,
    periodeSelesaiSubKegiatan: data?.periode_selesai_sub_kegiatan ?? null,
    isAset: data?.is_aset ?? false,
    isWip: data?.is_wip ?? false,
    idPenanggungJawabSubKegiatan:
      data?.id_penanggung_jawab_sub_kegiatan ?? null,
    namaPenanggungJawabSubKegiatan:
      data?.nama_penanggung_jawab_sub_kegiatan ?? "",
    lamaPeriodeSubKegiatan: data?.lama_periode_sub_kegiatan ?? "-",
    detailMaterial: data?.material
      ? data?.material?.map((val) => ({
          idItemSubKegiatan: val.id_item_sub_kegiatan_material,
          hargaSatuan: val.harga_satuan,
          namaSatuan: val.nama_satuan,
          idSumberPengadaan: val.id_sumber_pengadaan,
          namaSumberPengadaan: val.nama_sumber_pengadaan,
          qty: val.qty_item_sub_kegiatan_material,
          idKategori: val.id_kategori_anggaran,
          namaKategori: val.nama_kategori_anggaran,
          idItemSumberDaya: val.id_item_bahan,
          namaItemSumberDaya: val.nama_item,
        }))
      : [],
    detailManusia: data?.manusia
      ? data?.manusia?.map((val) => ({
          idItemSubKegiatan: val.id_item_sub_kegiatan_manusia,
          hargaSatuan: val.harga_satuan,
          namaSatuan: val.nama_satuan,
          idSumberPengadaan: val.id_sumber_pengadaan,
          namaSumberPengadaan: val.nama_sumber_pengadaan,
          qty: val.qty_item_sub_kegiatan_manusia,
          idKategori: val.id_kategori_anggaran,
          namaKategori: val.nama_kategori_anggaran,
          idItemSumberDaya: val.id_item_upah,
          namaItemSumberDaya: val.nama_item,
        }))
      : [],
    detailPeralatan: data?.peralatan
      ? data?.peralatan?.map((val) => ({
          idItemSubKegiatan: val.id_item_sub_kegiatan_peralatan,
          hargaSatuan: val.harga_satuan,
          namaSatuan: val.nama_satuan,
          idSumberPengadaan: val.id_sumber_pengadaan,
          namaSumberPengadaan: val.nama_sumber_pengadaan,
          qty: val.qty_item_sub_kegiatan_peralatan,
          idKategoriAset: val.id_kategori_aset,
          namaKategoriAset: val.nama_kategori_aset,
          idItemSumberDaya: val.id_item_alat_mesin,
          namaItemSumberDaya: val.nama_item_aset,
          idGrupAset: val.id_grup_aset,
          namaGrupAset: val.nama_grup_aset,
          idJenisAset: val.id_jenis_aset,
          namaJenisAset: val.nama_jenis_aset,
        }))
      : [],
    detailOverhead: data?.overhead
      ? data?.overhead?.map((val) => ({
          idItemSubKegiatan: val.id_item_sub_kegiatan_overhad,
          hargaSatuan: val.harga_satuan,
          namaSatuan: val.nama_satuan,
          idSumberPengadaan: val.id_sumber_pengadaan,
          namaSumberPengadaan: val.nama_sumber_pengadaan,
          qty: val.qty_item_sub_kegiatan_overhead,
          idKategori: val.id_kategori_anggaran,
          namaKategori: val.nama_kategori_anggaran,
          idItemSumberDaya: val.id_item_sumber_daya,
          namaItemSumberDaya: val.nama_item_sumber_daya,
        }))
      : [],
    detailLainnya: data?.lainnya
      ? data?.lainnya?.map((val) => ({
          idItemSubKegiatan: val.id_item_sub_kegiatan_lainnya,
          hargaSatuan: val.harga_satuan,
          namaSatuan: val.nama_satuan,
          idSumberPengadaan: val.id_sumber_pengadaan,
          namaSumberPengadaan: val.nama_sumber_pengadaan,
          qty: val.qty_item_sub_kegiatan_lainnya,
          idKategori: val.id_kategori_anggaran,
          namaKategori: val.nama_kategori_anggaran,
          idItemSumberDaya: val.id_item_sumber_daya,
          namaItemSumberDaya: val.nama_item_sumber_daya,
        }))
      : [],
  },
});
