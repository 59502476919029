import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import {
  Checkbox,
  DatePicker,
  InfoItemHorizontal,
  Input,
  RadioButton,
  RadioButtonWrapper,
  SelectSearch,
  TextArea,
} from "components";
import { useFormikContext } from "formik";
import Axios from "axios";
import { SubKegiatanApi } from "api";
import { DateConvert } from "utilities";

const InfoSection = ({ data }) => {
  return (
    <>
      <Row>
        <Col lg="6">
          <InfoItemHorizontal
            label="Tgl. Sub Kegiatan"
            text={
              data.tglSubKegiatan
                ? DateConvert(new Date(data.tglSubKegiatan)).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="No. Sub Kegiatan"
            text={data.noSubKegiatan ?? "-"}
          />
          <InfoItemHorizontal
            label="Nama Kegiatan"
            text={data.namaSubKegiatan ?? "-"}
          />
          <InfoItemHorizontal
            label="Penanggung Jawab"
            text={data.namaPenanggungJawabSubKegiatan ?? "-"}
          />
        </Col>
        <Col lg="6">
          <InfoItemHorizontal
            label="Periode Mulai"
            text={
              data.periodeMulaiSubKegiatan
                ? DateConvert(new Date(data.periodeMulaiSubKegiatan)).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="Periode Selesai"
            text={
              data.periodeSelesaiSubKegiatan
                ? DateConvert(new Date(data.periodeSelesaiSubKegiatan)).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="Lama Periode"
            text={`${data.lamaPeriodeSubKegiatan ?? "-"} Hari`}
          />
          <InfoItemHorizontal
            label="Deskripsi"
            text={data.deskripsiSubKegiatan ?? "-"}
          />
        </Col>
      </Row>
    </>
  );
};

export const FormSection = ({ type }) => {
  const { values, setValues, errors, touched } = useFormikContext();
  const [isNomorLoading, setIsNomorLoading] = useState(false);
  const [dropdownPenanggungJawab, setDropdownPenanggungJawab] = useState([]);

  const getNomorHandler = (tgl) => {
    setIsNomorLoading(true);
    const tanggal = DateConvert(new Date(tgl)).default;

    SubKegiatanApi.getKode({ tanggal })
      .then(({ data }) => {
        const no = data?.data;
        setValues({
          ...values,
          subKegiatan: {
            ...values.subKegiatan,
            noSubKegiatan: no,
            tglSubKegiatan: tanggal,
          },
        });
      })
      .catch(() => {
        setValues({
          ...values,
          subKegiatan: {
            ...values.subKegiatan,
            noSubKegiatan: "",
            tglSubKegiatan: null,
          },
        });
        window.alert("Nomor gagal dimuat");
      })
      .finally(() => {
        setIsNomorLoading(false);
      });
  };

  const getDropdown = () => {
    Axios.all([SubKegiatanApi.dropdownKaryawan()]).then(
      Axios.spread((karyawan) => {
        const mappingDropdownPenanggungJawab = (data) =>
          data.map((val) => ({
            label: val.nama_karyawan,
            value: val.id_karyawan,
          }));

        setDropdownPenanggungJawab(
          mappingDropdownPenanggungJawab(karyawan?.data?.data ?? [])
        );
      })
    );
  };

  useEffect(() => {
    type !== "read" && getDropdown();

    return () => {};
  }, []);

  return type === "read" ? (
    <InfoSection data={values?.subKegiatan} />
  ) : (
    <Row>
      <Col lg="6">
        <DatePicker
          label="Tgl. Sub Kegiatan"
          dateFormat="dd/MM/yyyy"
          onChange={(date) => {
            getNomorHandler(date);
          }}
          selected={
            values?.subKegiatan?.tglSubKegiatan
              ? new Date(values?.subKegiatan?.tglSubKegiatan)
              : null
          }
          error={
            Boolean(errors?.subKegiatan?.tglSubKegiatan) &&
            touched?.subKegiatan?.tglSubKegiatan
          }
          errorText={
            Boolean(errors?.subKegiatan?.tglSubKegiatan) &&
            touched?.subKegiatan?.tglSubKegiatan &&
            errors?.subKegiatan?.tglSubKegiatan
          }
        />
      </Col>
      <Col lg="6">
        <Input
          label="No. Sub Kegiatan"
          disabled
          value={
            isNomorLoading
              ? "Memuat Data.."
              : values?.subKegiatan?.noSubKegiatan
          }
          placeholder="Pilih tanggal terlebih dahulu"
        />
      </Col>
      <Col lg="6">
        <Input
          label="Nama Sub Kegiatan"
          name="namaSubKegiatan"
          type="text"
          placeholder="Masukan nama sub kegiatan"
          onChange={(e) =>
            setValues({
              ...values,
              subKegiatan: {
                ...values.subKegiatan,
                namaSubKegiatan: e?.target?.value,
              },
            })
          }
          value={values?.subKegiatan?.namaSubKegiatan}
          error={
            Boolean(errors?.subKegiatan?.namaSubKegiatan) &&
            touched?.subKegiatan?.namaSubKegiatan
          }
          errorText={
            Boolean(errors?.subKegiatan?.namaSubKegiatan) &&
            touched?.subKegiatan?.namaSubKegiatan &&
            errors?.subKegiatan?.namaSubKegiatan
          }
        />
        <DatePicker
          label="Periode Mulai"
          dateFormat="dd/MM/yyyy"
          onChange={(date) =>
            setValues({
              ...values,
              subKegiatan: {
                ...values.subKegiatan,
                periodeMulaiSubKegiatan: DateConvert(new Date(date)).default,
              },
            })
          }
          selected={
            values?.subKegiatan?.periodeMulaiSubKegiatan
              ? new Date(values?.subKegiatan?.periodeMulaiSubKegiatan)
              : null
          }
          error={
            Boolean(errors?.subKegiatan?.periodeMulaiSubKegiatan) &&
            touched?.subKegiatan?.periodeMulaiSubKegiatan
          }
          errorText={
            Boolean(errors?.subKegiatan?.periodeMulaiSubKegiatan) &&
            touched?.subKegiatan?.periodeMulaiSubKegiatan &&
            errors?.subKegiatan?.periodeMulaiSubKegiatan
          }
        />
      </Col>
      <Col lg="6">
        <SelectSearch
          key={values?.subKegiatan?.idPenanggungJawabSubKegiatan}
          label="Penanggung Jawab"
          placeholder="Pilih penanggung jawab"
          option={dropdownPenanggungJawab}
          onChange={(res) =>
            setValues({
              ...values,
              subKegiatan: {
                ...values.subKegiatan,
                idPenanggungJawabSubKegiatan: res.value,
                namaPenanggungJawabSubKegiatan: res.label,
              },
            })
          }
          defaultValue={
            values?.subKegiatan?.idPenanggungJawabSubKegiatan && {
              value: values?.subKegiatan?.idPenanggungJawabSubKegiatan,
              label: values?.subKegiatan?.namaPenanggungJawabSubKegiatan,
            }
          }
          error={
            Boolean(errors?.subKegiatan?.idPenanggungJawabSubKegiatan) &&
            touched?.subKegiatan?.idPenanggungJawabSubKegiatan
          }
          errorText={
            Boolean(errors?.subKegiatan?.idPenanggungJawabSubKegiatan) &&
            touched?.subKegiatan?.idPenanggungJawabSubKegiatan &&
            errors?.subKegiatan?.idPenanggungJawabSubKegiatan
          }
        />
        <DatePicker
          label="Periode Selesai"
          dateFormat="dd/MM/yyyy"
          onChange={(date) =>
            setValues({
              ...values,
              subKegiatan: {
                ...values.subKegiatan,
                periodeSelesaiSubKegiatan: DateConvert(new Date(date)).default,
              },
            })
          }
          selected={
            values?.subKegiatan?.periodeSelesaiSubKegiatan
              ? new Date(values?.subKegiatan?.periodeSelesaiSubKegiatan)
              : null
          }
          error={
            Boolean(errors?.subKegiatan?.periodeSelesaiSubKegiatan) &&
            touched?.subKegiatan?.periodeSelesaiSubKegiatan
          }
          errorText={
            Boolean(errors?.subKegiatan?.periodeSelesaiSubKegiatan) &&
            touched?.subKegiatan?.periodeSelesaiSubKegiatan &&
            errors?.subKegiatan?.periodeSelesaiSubKegiatan
          }
        />
      </Col>
      <Col lg="12">
        <TextArea
          label="Deskripsi"
          rows="4"
          placeholder="Masukan deskripsi"
          name="deskripsiSubKegiatan"
          onChange={(e) =>
            setValues({
              ...values,
              subKegiatan: {
                ...values?.subKegiatan,
                deskripsiSubKegiatan: e?.target?.value,
              },
            })
          }
          value={values?.subKegiatan?.deskripsiSubKegiatan}
          error={
            Boolean(errors?.subKegiatan?.deskripsiSubKegiatan) &&
            touched?.subKegiatan?.deskripsiSubKegiatan
          }
          errorText={
            Boolean(errors?.subKegiatan?.deskripsiSubKegiatan) &&
            touched?.subKegiatan?.deskripsiSubKegiatan &&
            errors?.subKegiatan?.deskripsiSubKegiatan
          }
        />

        <Form.Check
          checked={!values?.subKegiatan?.isAset}
          label="Non Aset"
          value="nonAset"
          type="radio"
          onChange={() =>
            setValues({
              ...values,
              subKegiatan: {
                ...values?.subKegiatan,
                isAset: false,
                isWip: !values?.subKegiatan?.isAset && false,
              },
            })
          }
        />

        <Form.Check
          checked={values?.subKegiatan?.isAset}
          label="Aset"
          value="aset"
          type="radio"
          onChange={() =>
            setValues({
              ...values,
              subKegiatan: {
                ...values?.subKegiatan,
                isAset: true,
              },
            })
          }
        />

        <Form.Check
          checked={values?.subKegiatan?.isWip}
          label="WIP"
          value="wip"
          type="radio"
          className="ml-4"
          onChange={() =>
            setValues({
              ...values,
              subKegiatan: {
                ...values?.subKegiatan,
                isWip: true,
              },
            })
          }
          disabled={!values?.subKegiatan?.isAset}
        />

        <Form.Check
          checked={!values?.subKegiatan?.isWip}
          label="Non WIP"
          value="nonWip"
          type="radio"
          className="ml-4"
          onChange={() =>
            setValues({
              ...values,
              subKegiatan: {
                ...values?.subKegiatan,
                isWip: false,
              },
            })
          }
          disabled={!values?.subKegiatan?.isAset}
        />
      </Col>
    </Row>
  );
};
