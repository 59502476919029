export const formSubmmitMapper = (data) => ({
  nama_program: data?.namaProgram,
  deskripsi: data?.deskripsi,
  tgl_program: data?.tglProgram,
  id_penanggung_jawab: data?.idPenanggungJawab,
  id_unit_organisasi: data?.idUnitOrganisasi,
  periode_mulai: data?.periodeMulai,
  periode_selesai: data?.periodeSelesai,
  tgl_input: data?.tglInput,
  id_program: data?.idProgram,
  id_unit_produksi: data?.idUnitProduksi,
});
