export const formSubmmitMapper = (data) => ({
  id_kegiatan: data?.kegiatan?.idKegiatan,
  id_program: data?.program?.idProgram,
  deskripsi: data?.kegiatan?.deskripsiKegiatan,
  id_penanggung_jawab: data?.kegiatan?.idPenanggungJawabKegiatan,
  tgl_kegiatan: data?.kegiatan?.tglKegiatan,
  tgl_input: data?.kegiatan?.tglInputKegiatan,
  periode_mulai: data?.kegiatan?.periodeMulaiKegiatan,
  periode_selesai: data?.kegiatan?.periodeSelesaiKegiatan,
  id_jenis_anggaran: data?.kegiatan?.idJenisAnggaran,
  id_kelompok_anggaran: data?.kegiatan?.idKelompokAnggaran,
  id_sub_kelompok_anggaran: data?.kegiatan?.idSubKelompokAnggaran,
  nama_kegiatan: data?.kegiatan?.namaKegiatan,
});
