export const formSubmmitMapper = (data) => ({
  id_realisasi_ppa: data?.realisasiPPA?.idRealisasiPPA,
  tgl_realisasi_ppa: data?.realisasiPPA?.tglRealisasiPPA,
  id_ppa: data?.PPA?.idPPA,
  material: data.subKegiatan.detailMaterial
    ? data.subKegiatan.detailMaterial
        .filter((res) => res.qtyRealisasi > 0)
        .map((val) => ({
          id_item_ppa_material: val?.idItemPPA,
          qty_realisasi_ppa: val?.qtyRealisasi,
          harga_realisasi_ppa: val?.nominalRealisasi,
          bukti: val?.bukti,
        }))
    : [],
  manusia: data.subKegiatan.detailManusia
    ? data.subKegiatan.detailManusia
        .filter((res) => res.qtyRealisasi > 0)
        .map((val) => ({
          id_item_ppa_manusia: val?.idItemPPA,
          qty_realisasi_ppa: val?.qtyRealisasi,
          harga_realisasi_ppa: val?.nominalRealisasi,
          bukti: val?.bukti,
        }))
    : [],
  peralatan: data.subKegiatan.detailPeralatan
    ? data.subKegiatan.detailPeralatan
        .filter((res) => res.qtyRealisasi > 0)
        .map((val) => ({
          id_item_ppa_peralatan: val?.idItemPPA,
          qty_realisasi_ppa: val?.qtyRealisasi,
          harga_realisasi_ppa: val?.nominalRealisasi,
          bukti: val?.bukti,
        }))
    : [],
  overhead: data.subKegiatan.detailOverhead
    ? data.subKegiatan.detailOverhead
        .filter((res) => res.qtyRealisasi > 0)
        .map((val) => ({
          id_item_ppa_overhead: val?.idItemPPA,
          qty_realisasi_ppa: val?.qtyRealisasi,
          harga_realisasi_ppa: val?.nominalRealisasi,
          bukti: val?.bukti,
        }))
    : [],
  lainnya: data.subKegiatan.detailLainnya
    ? data.subKegiatan.detailLainnya
        .filter((res) => res.qtyRealisasi > 0)
        .map((val) => ({
          id_item_ppa_lainnya: val?.idItemPPA,
          qty_realisasi_ppa: val?.qtyRealisasi,
          harga_realisasi_ppa: val?.nominalRealisasi,
          bukti: val?.bukti,
        }))
    : [],
});
