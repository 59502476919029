import { DateConvert } from "utilities";

export const formInitialValues = (data) => ({
  program: {
    idProgram: data?.id_program ?? undefined,
    namaProgram: data?.nama_program ?? "",
    deskripsiProgram: data?.deskripsi_program ?? "",
    tglProgram: data?.tgl_program ?? null,
    idPenanggungJawabProgram: data?.id_penanggung_jawab_program ?? undefined,
    namaPenanggungJawabProgram: data?.nama_penanggung_jawab_program ?? "",
    idUnitOrganisasiProgram: data?.id_unit_organisasi ?? undefined,
    namaUnitOrganisasiProgram: data?.nama_unit_organisasi ?? "",
    periodeMulaiProgram: data?.periode_mulai_program ?? null,
    periodeSelesaiProgram: data?.periode_selesai_program ?? null,
    tglInputProgram: data?.tgl_input ?? DateConvert(new Date()).default,
    noProgram: data?.no_program ?? "",
    lamaPeriodeProgram: data?.lama_periode_program ?? "",
  },
  kegiatan: {
    deskripsiKegiatan: data?.deskripsi_kegiatan ?? "",
    idPenanggungJawabKegiatan: data?.id_penanggung_jawab_kegiatan ?? null,
    namaPenanggungJawabKegiatan: data?.nama_penanggung_jawab_kegiatan ?? "",
    tglKegiatan: data?.tgl_kegiatan ?? null,
    tglInputKegiatan: data?.tgl_input ?? DateConvert(new Date()).default,
    periodeMulaiKegiatan: data?.periode_mulai_kegiatan ?? null,
    periodeSelesaiKegiatan: data?.periode_selesai_kegiatan ?? null,
    idJenisAnggaran: data?.id_jenis_anggaran ?? null,
    namaJenisAnggaran: data?.nama_jenis_anggaran ?? "",
    idKelompokAnggaran: data?.id_kelompok_anggaran ?? null,
    namaKelompokAnggaran: data?.nama_kelompok_anggaran ?? "",
    idSubKelompokAnggaran: data?.id_sub_kelompok_anggaran ?? null,
    namaSubKelompokAnggaran: data?.nama_sub_kelompok_anggaran ?? "",
    namaKegiatan: data?.nama_kegiatan ?? "",
    noKegiatan: data?.no_kegiatan ?? "",
    idKegiatan: data?.id_kegiatan ?? undefined,
    lamaPeriodeKegiatan: data?.lama_periode_kegiatan ?? "",
  },
});
