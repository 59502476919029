import Services from "../../services";

class ItemSumberDayaApi {
  dropdownKategori() {
    return Services.get("/kategori_anggaran/dropdown");
  }

  dropdownSatuan() {
    return Services.get("/dropdown/satuan");
  }

  getPage(params) {
    return Services.get("/item_sumber_daya/", { params });
  }

  getKode() {
    return Services.get("/item_sumber_daya/no_baru");
  }

  show(value) {
    return Services.put("/item_sumber_daya/show", value);
  }

  hide(value) {
    return Services.put("/item_sumber_daya/hide", value);
  }

  create(value) {
    return Services.post("/item_sumber_daya", value);
  }

  update(value) {
    return Services.put("/item_sumber_daya", value);
  }

  delete(value) {
    return Services.post("/item_sumber_daya/delete", value);
  }
}

export default new ItemSumberDayaApi();
