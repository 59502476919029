import React from "react";
import { Card, Col, Row } from "react-bootstrap";

export const CatatanApprovalSection = ({ data }) => {
  const InfoItem = ({
    labelHeader,
    karyawanName,
    labelCatatan,
    catatanValue,
  }) => (
    <>
      <small>{labelHeader}</small>
      <p>
        <b>{karyawanName}</b>
      </p>
      <small>{labelCatatan}</small>
      <p>
        <b>{catatanValue}</b>
      </p>
    </>
  );

  return (
    <>
      <div className="mt-4">
        <span style={{ fontSize: "14px" }}>
          <b>Catatan Approval Program</b>
        </span>
      </div>
      <Card>
        <Card.Body>
          <Row>
            {data === undefined ? (
              <Col className="text-center">
                <small>Memuat Data...</small>
              </Col>
            ) : (
              data?.map((val, index) => (
                <>
                  <Col md={3} key={index}>
                    <InfoItem
                      labelHeader={
                        val.status_approval === "APP"
                          ? "Pengesah"
                          : index === 0
                          ? "Pengaju"
                          : `Pemeriksa ${
                              val.approval_level !== "0"
                                ? val.approval_level
                                : ""
                            }`
                      }
                      karyawanName={val.nama_karyawan ?? "-"}
                      labelCatatan={
                        val.status_approval === "APP"
                          ? "Catatan Pengesah"
                          : index !== 0
                          ? `Catatan Pemeriksa ${
                              val.approval_level !== "0"
                                ? val.approval_level
                                : ""
                            }`
                          : ""
                      }
                      catatanValue={index !== 0 ? val.catatan : ""}
                    />
                  </Col>
                </>
              ))
            )}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
