import React, { useState, useEffect } from "react";
import {
  Table,
  Th,
  Td,
  DataStatus,
  TdFixed,
  InputQtyDecimal,
  ThFixed,
  Tr,
  THead,
  TBody,
  ActionButton,
  SelectSearch,
  CreateButton,
  UpdateButton,
  DeleteButton,
  NumberFormat,
} from "components";
import { useLocation } from "react-router-dom";
import { Formik, useFormikContext } from "formik";

import { SubKegiatanApi } from "api";
import * as Yup from "yup";
import { ButtonGroup } from "react-bootstrap";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { DecimalConvert, RupiahConvert } from "utilities";

export const TableItemSumberDayaSection = ({
  dropdown,
  type,
  tabType,
  withPPA,
}) => {
  const location = useLocation();
  const [update, setUpdate] = useState("");
  const { values, setValues } = useFormikContext();
  const checkKeyTab =
    tabType === "material"
      ? "detailMaterial"
      : tabType === "manusia"
      ? "detailManusia"
      : tabType === "peralatan"
      ? "detailPeralatan"
      : tabType === "overhead"
      ? "detailOverhead"
      : "detailLainnya";

  const TableForm = ({ data }) => {
    const tableFormIntialValues = {
      idKategori: data?.idKategori ?? null,
      namaKategori: data?.namaKategori ?? null,
      idItemSumberDaya: data?.idItemSumberDaya ?? null,
      namaItemSumberDaya: data?.namaItemSumberDaya ?? null,
      namaSatuan: data?.namaSatuan ?? null,
      idSatuan: data?.idSatuan ?? null,
      qty: parseFloat(data?.qty ?? 0),
      idSumberPengadaan: data?.idSumberPengadaan ?? null,
      namaSumberPengadaan: data?.namaSumberPengadaan ?? null,
      hargaSatuan: parseFloat(data?.hargaSatuan ?? 0),
    };
    const tableValidationSchema = Yup.object().shape({
      idKategori: Yup.string().required("Pilih kategori").nullable(),
      qty: Yup.string().required("Masukan qty").nullable(),
      idItemSumberDaya: Yup.string()
        .required("Pilih item sumber daya")
        .nullable(),
      idSumberPengadaan: Yup.string()
        .required("Pilih item sumber pengadaan")
        .nullable(),
      hargaSatuan: Yup.string().required("Masukan harga satuan").nullable(),
    });

    const setSubmitHandler = (value, key) => {
      if (data) {
        setValues({
          ...values,
          subKegiatan: {
            ...values.subKegiatan,
            [key]: values?.subKegiatan?.[key].map((val, index) =>
              update === index ? value : val
            ),
          },
        });
        setUpdate(undefined);
      }
      if (!data) {
        setValues({
          ...values,
          subKegiatan: {
            ...values?.subKegiatan,
            [key]: [value, ...values?.subKegiatan?.[key]],
          },
        });
      }
    };
    const tableSubmitHandler = (value) => {
      tabType === "material" && setSubmitHandler(value, "detailMaterial");
      tabType === "manusia" && setSubmitHandler(value, "detailManusia");
      tabType === "peralatan" && setSubmitHandler(value, "detailPeralatan");
      tabType === "overhead" && setSubmitHandler(value, "detailOverhead");
      tabType === "lainnya" && setSubmitHandler(value, "detailLainnya");
    };

    return (
      <Formik
        enableReinitialize
        initialValues={tableFormIntialValues}
        validationSchema={tableValidationSchema}
        onSubmit={tableSubmitHandler}
      >
        {({ values, setValues, errors, touched, handleSubmit }) => (
          <Tr>
            <Td colSpan="2">
              <SelectSearch
                placeholder="Pilih kategori anggaran"
                option={dropdown?.kategori}
                defaultValue={{
                  value: values.idKategori ?? "",
                  label: values.namaKategori ?? "Pilih kategori anggaran",
                }}
                onChange={(val) => {
                  setValues({
                    ...values,
                    idKategori: val.value,
                    namaKategori: val.label,
                  });
                }}
                error={Boolean(errors.idKategori) && touched.idKategori}
                errorText={
                  Boolean(errors.idKategori) &&
                  touched.idKategori &&
                  errors.idKategori
                }
              />
            </Td>
            <Td>
              <SelectSearch
                placeholder="Pilih item sumber daya"
                option={
                  tabType === "material"
                    ? dropdown?.itemSumberDayaMaterial
                    : tabType === "manusia"
                    ? dropdown?.itemSumberDayaManusia
                    : tabType === "peralatan"
                    ? dropdown?.itemSumberDayaPeralatan
                    : tabType === "overhead"
                    ? dropdown?.itemSumberDayaOverhead
                    : dropdown?.itemSumberDayaLainnya
                }
                defaultValue={{
                  value: values.idItemSumberDaya ?? "",
                  label: values.namaItemSumberDaya ?? "Pilih item convert",
                }}
                onChange={(val) => {
                  setValues({
                    ...values,
                    idItemSumberDaya: val.value,
                    namaItemSumberDaya: val.label,
                  });
                }}
                error={
                  Boolean(errors.idItemSumberDaya) && touched.idItemSumberDaya
                }
                errorText={
                  Boolean(errors.idItemSumberDaya) &&
                  touched.idItemSumberDaya &&
                  errors.idItemSumberDaya
                }
              />
            </Td>
            <Td>{values?.namaSatuan ?? "-"}</Td>
            <Td>
              <SelectSearch
                placeholder="Pilih sumber pengadaan"
                option={dropdown?.sumberPengadaan}
                defaultValue={{
                  value: values.idSumberPengadaan ?? "",
                  label: values.namaSumberPengadaan ?? "Pilih item convert",
                }}
                onChange={(val) => {
                  setValues({
                    ...values,
                    idSumberPengadaan: val.value,
                    namaSumberPengadaan: val.label,
                  });
                }}
                error={
                  Boolean(errors.idSumberPengadaan) && touched.idSumberPengadaan
                }
                errorText={
                  Boolean(errors.idSumberPengadaan) &&
                  touched.idSumberPengadaan &&
                  errors.idSumberPengadaan
                }
              />
            </Td>
            <Td>
              <InputQtyDecimal
                placeholder="Masukan qty"
                value={values?.qty ?? null}
                onChange={(e) => setValues({ ...values, qty: e })}
                error={Boolean(errors.qty) && touched.qty}
                errorText={Boolean(errors.qty) && touched.qty && errors.qty}
              />
            </Td>
            <Td>
              <NumberFormat
                placeholder="Masukan harga satuan"
                value={values?.hargaSatuan}
                decimalSeparator=","
                onChange={(e) => setValues({ ...values, hargaSatuan: e })}
                error={Boolean(errors.hargaSatuan) && touched.hargaSatuan}
                errorText={
                  Boolean(errors.hargaSatuan) &&
                  touched.hargaSatuan &&
                  errors.hargaSatuan
                }
              />
            </Td>
            <Td>
              {
                RupiahConvert(
                  String(
                    parseFloat(values.hargaSatuan ?? 0) *
                      parseFloat(values.qty ?? 0)
                  )
                ).getWithComa
              }
            </Td>
            <TdFixed>
              {data ? (
                <>
                  <ButtonGroup>
                    <ActionButton
                      text={<AiOutlineCheck />}
                      variant="success"
                      onClick={handleSubmit}
                      size="sm"
                    />
                    <ActionButton
                      text={<AiOutlineClose />}
                      variant="danger"
                      onClick={() => setUpdate(undefined)}
                      size="sm"
                    />
                  </ButtonGroup>
                </>
              ) : (
                <CreateButton
                  noText
                  icon
                  className="px-4"
                  onClick={handleSubmit}
                  size="sm"
                />
              )}
            </TdFixed>
          </Tr>
        )}
      </Formik>
    );
  };

  return (
    <>
      <Table>
        <THead>
          <Tr>
            <Th>No</Th>
            <Th>Kategori Anggaran</Th>
            <Th>Item Sumber Daya</Th>
            <Th>Satuan</Th>
            <Th>Sumber Pengadaan</Th>
            <Th>Qty.</Th>
            <Th>Harga Satuan</Th>
            {withPPA && <Th>Harga Satuan PPA</Th>}
            <Th>Sub Total</Th>
            {/* {Boolean(type !== "read") && <ThFixed>Aksi</ThFixed>} */}
          </Tr>
        </THead>
        <TBody>
          {/* {Boolean(type !== "read") && <TableForm />} */}
          {values?.subKegiatan?.[checkKeyTab]?.length > 0 ? (
            values?.subKegiatan?.[checkKeyTab]?.map((val, index) =>
              update === index ? (
                <TableForm data={val} />
              ) : (
                <Tr key={index}>
                  <TdFixed>{index + 1}</TdFixed>
                  <TdFixed>{val.namaKategori ?? "-"}</TdFixed>
                  <Td>{val.namaItemSumberDaya ?? "-"}</Td>
                  <Td>{val.namaSatuan ?? "-"}</Td>
                  <Td>{val.namaSumberPengadaan ?? "-"}</Td>
                  <Td className="text-right">
                    {DecimalConvert(parseFloat(val.qty ?? 0)).getWithComa}
                  </Td>
                  <Td className="text-right">
                    {
                      RupiahConvert(parseFloat(val.hargaSatuan ?? 0).toString())
                        .getWithComa
                    }
                  </Td>
                  {type === "create" ? (
                    ""
                  ) : (
                    <Td className="text-right">
                      {type === "update" ? (
                        <NumberFormat
                          placeholder="Masukan harga satuan"
                          value={parseFloat(val?.hargaSatuanPPA ?? 0)}
                          decimalSeparator=","
                          onChange={(e) =>
                            setValues({
                              ...values,
                              subKegiatan: {
                                ...values.subKegiatan,
                                [checkKeyTab]: values?.subKegiatan?.[
                                  checkKeyTab
                                ]?.map((res, i) =>
                                  index === i
                                    ? { ...res, hargaSatuanPPA: e }
                                    : res
                                ),
                              },
                            })
                          }
                        />
                      ) : (
                        RupiahConvert(
                          parseFloat(val?.hargaSatuanPPA ?? 0).toString()
                        ).getWithComa
                      )}
                    </Td>
                  )}
                  <Td className="text-right">
                    {withPPA
                      ? RupiahConvert(
                          String(
                            parseFloat(val.hargaSatuanPPA ?? 0) *
                              parseFloat(val.qty ?? 0)
                          )
                        ).getWithComa
                      : RupiahConvert(
                          String(
                            parseFloat(val.hargaSatuan ?? 0) *
                              parseFloat(val.qty ?? 0)
                          )
                        ).getWithComa}
                  </Td>

                  {/* {Boolean(type !== "read") && (
                    <TdFixed>
                      <ButtonGroup>
                        <UpdateButton
                          noText
                          icon
                          variant="success"
                          onClick={() => setUpdate(index)}
                          size="sm"
                        />
                        <DeleteButton
                          noText
                          icon
                          onClick={() => {
                            const filterData = values.subKegiatan?.[
                              checkKeyTab
                            ]?.filter((res, ind) => ind !== index);

                            setValues({
                              ...values,
                              subKegiatan: {
                                ...values?.subKegiatan,
                                [checkKeyTab]: filterData,
                              },
                            });
                          }}
                          size="sm"
                        />
                      </ButtonGroup>
                    </TdFixed>
                  )} */}
                </Tr>
              )
            )
          ) : (
            <tr>
              <td colSpan={10}>
                <DataStatus text="Tidak ada data" />
              </td>
            </tr>
          )}
        </TBody>
      </Table>
    </>
  );
};
