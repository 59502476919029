import Services from "../../services";

class MappingCoaItemSumberDayaApi {
  dropdownCOA() {
    return Services.get("/dropdown/coa");
  }

  getPage(params) {
    return Services.get("/item_sumber_daya", { params });
  }

  update(value) {
    return Services.put("/item_sumber_daya/coa", value);
  }
}

export default new MappingCoaItemSumberDayaApi();
