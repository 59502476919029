import React, { useState, useEffect } from "react";
import Axios from "axios";
import {
  CRUDLayout,
  InputSearch,
  // ExportButton,
  CreateButton,
  DataStatus,
  UpdateButton,
  Pagination,
  Alert,
  Th,
  Td,
  Tr,
  TBody,
  TdFixed,
  ReadButton,
  ApprovalStatusButton,
  FilterButton,
  ActionButton,
  BackButton,
} from "components";
import { RealisasiPPAApi } from "api";
import { DateConvert, TableNumber } from "utilities";
import {
  ModalDetailKegiatan,
  ModalDetailProgram,
  ModalFilter,
  ModalFilterKegiatan,
} from "./Comps";
import Thead from "components/Table/THead";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { ModalFilterProgram } from "./Comps/ModalFilterProgram";

export const ListPPARealisasiPPA = ({ setNavbarTitle }) => {
  // Title
  const title = "Realisasi PPA";

  const history = useHistory();

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(true);

  // STATE SEARCHING
  const [searchKey, setSearchKey] = useState("");

  // STATE DATA KELOMPOK
  const [data, setData] = useState([]);

  const [dropdown, setDropdown] = useState({
    unitOrganisasi: [],
    penanggungJawab: [],
  });

  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: false,
      tgl_program_start: undefined,
      tgl_program_end: undefined,
      tgl_kegiatan_start: undefined,
      tgl_kegiatan_end: undefined,
      tgl_sub_kegiatan_start: undefined,
      tgl_sub_kegiatan_end: undefined,
      tgl_ppa_start: undefined,
      tgl_ppa_end: undefined,
    },
    pagination: {
      page: "1",
      dataLength: "10",
      totalPage: "1",
      dataCount: "0",
    },
  });

  const [showModalFilter, setShowModalFilter] = useState(false);

  const [modalConfig, setModalConfig] = useState({
    type: "add",
    show: false,
    id: "",
  });

  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
    show: false,
  });

  const [searchTime, setSearchTime] = useState(null);

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true);

    Axios.all([
      RealisasiPPAApi.getPagePPA({
        q: searchKey,
        page: dataFilter?.pagination?.page,
        per_page: dataFilter?.pagination?.dataLength,
        tgl_program_start: dataFilter?.filter?.tgl_program_start,
        tgl_program_end: dataFilter?.filter?.tgl_program_end,
        tgl_kegiatan_start: dataFilter?.filter?.tgl_kegiatan_start,
        tgl_kegiatan_end: dataFilter?.filter?.tgl_kegiatan_end,
        tgl_sub_kegiatan_start: dataFilter?.filter?.tgl_sub_kegiatan_start,
        tgl_sub_kegiatan_end: dataFilter?.filter?.tgl_sub_kegiatan_end,
        tgl_ppa_start: dataFilter?.filter?.tgl_ppa_start,
        tgl_ppa_end: dataFilter?.filter?.tgl_ppa_end,
        status_approval_ppa: "APP",
      }),
    ])
      .then(
        Axios.spread((res) => {
          setData(res?.data?.data?.data);

          setDataFilter({
            ...dataFilter,
            pagination: {
              ...dataFilter.pagination,
              dataCount: res?.data?.data?.data_count,
              totalPage: res?.data?.data?.total_page,
            },
          });
        })
      )
      .catch((err) => alert(err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  // FUNCTION GET DATA DROPDOWN DARI SERVER
  const getDropdown = () => {
    Axios.all([
      RealisasiPPAApi.dropdownUnitOrganisasi(),
      RealisasiPPAApi.dropdownKaryawan(),
    ]).then(
      Axios.spread((unit, karyawan) => {
        const mappingDropdownUnitOrganisasi = (data) =>
          data.map((val) => ({
            label: val.nama_unit_organisasi,
            value: val.id_unit_organisasi,
          }));
        const mappingDropdownPenanggungJawab = (data) =>
          data.map((val) => ({
            label: val.nama_karyawan,
            value: val.id_karyawan,
          }));
        setDropdown({
          unitOrganisasi: mappingDropdownUnitOrganisasi(unit?.data?.data ?? []),
          penanggungJawab: mappingDropdownPenanggungJawab(
            karyawan?.data?.data ?? []
          ),
        });
      })
    );
  };

  const onInputSearch = (text) => {
    const key = text.target.value;
    if (searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setSearchKey(key);
        setAlertConfig({
          variant: "primary",
          text: `Hasil Pencarian : ${key}`,
          show: key === "" ? false : true,
        });
      }, 750)
    );
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title); // SET JUDUL NAVBAR
    getData(); // GET DATA DARI SERVER
    getDropdown();

    return () => {
      setIsLoading(false);
    };
  }, [
    setNavbarTitle,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_program_start,
    dataFilter?.filter?.tgl_program_end,
    dataFilter?.filter?.tgl_kegiatan_start,
    dataFilter?.filter?.tgl_kegiatan_end,
    dataFilter?.filter?.tgl_sub_kegiatan_start,
    dataFilter?.filter?.tgl_sub_kegiatan_end,
    dataFilter?.filter?.tgl_ppa_start,
    dataFilter?.filter?.tgl_ppa_end,
    searchKey,
  ]);

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <Thead>
            <Tr>
              <Th>No.</Th>
              <Th>Informasi PPA</Th>
              <Th>Informasi Sub Kegiatan</Th>
              <Th>Informasi Kegiatan</Th>
              <Th>Informasi Program</Th>
              <Th>Program</Th>
              <Th>Kegiatan</Th>
              <Th>Sub Kegiatan</Th>
              <Th>Aksi</Th>
            </Tr>
          </Thead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr>
                  <Td className="text-center" width="20px">
                    {TableNumber(
                      dataFilter?.pagination?.page,
                      dataFilter?.pagination?.dataLength,
                      index
                    )}
                  </Td>
                  <TdFixed>
                    <div>
                      {val.tgl_ppa
                        ? DateConvert(new Date(val?.tgl_ppa)).defaultDMY
                        : "-"}
                    </div>
                    <div>{val.no_ppa ?? ""}</div>
                  </TdFixed>
                  <TdFixed>
                    <div>
                      {val.tgl_sub_kegiatan
                        ? DateConvert(new Date(val?.tgl_sub_kegiatan))
                            .defaultDMY
                        : "-"}
                    </div>
                    <div>{val.no_sub_kegiatan ?? ""}</div>
                  </TdFixed>
                  <TdFixed>
                    <div>
                      {val.tgl_kegiatan
                        ? DateConvert(new Date(val?.tgl_kegiatan)).defaultDMY
                        : "-"}
                    </div>
                    <div>{val.no_kegiatan ?? ""}</div>
                  </TdFixed>
                  <TdFixed>
                    <div>
                      {val.tgl_program
                        ? DateConvert(new Date(val?.tgl_program)).defaultDMY
                        : "-"}
                    </div>
                    <div>{val.no_program ?? ""}</div>
                  </TdFixed>
                  <Td>{val.nama_program ?? "-"}</Td>
                  <Td>{val.nama_kegiatan ?? "-"}</Td>
                  <Td>{val.nama_sub_kegiatan ?? "-"}</Td>

                  <TdFixed>
                    <div className="d-flex justify-content-center">
                      <CreateButton
                        noText
                        size="sm"
                        className="my-1 mr-1"
                        onClick={() => history.push("tambah/" + val.id_ppa)}
                      />
                    </div>
                  </TdFixed>
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>
        {data.length > 0 && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength -
              dataFilter?.pagination?.dataLength +
              1
            }
            dataPage={
              dataFilter?.pagination?.dataCount <
              dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page *
                  dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                },
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                },
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <>
      <CRUDLayout>
        <CRUDLayout.Head>
          <CRUDLayout.HeadSearchSection>
            <div className="d-flex mb-3">
              <InputSearch
                onChange={(e) => {
                  onInputSearch(e);
                }}
              />
              <FilterButton
                active={dataFilter?.filter?.active}
                onClick={() => setShowModalFilter(true)}
              />
            </div>
          </CRUDLayout.HeadSearchSection>

          {/* Button Section */}
          <CRUDLayout.HeadButtonSection>
            <BackButton size="sm" onClick={() => history.goBack()} />
          </CRUDLayout.HeadButtonSection>
        </CRUDLayout.Head>

        {/* Alert */}
        <Alert
          show={alertConfig.show}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setAlertConfig({ ...alertConfig, show: false })}
        />

        {isLoading ? (
          <DataStatus loading={isLoading} text="Memuat Data" />
        ) : !Array.isArray(data) ? (
          <DataStatus text="Tidak dapat memuat data" />
        ) : data.length > 0 ? (
          <>
            <b>List Data PPA Yang Siap Direalisasi</b>
            <Table />
          </>
        ) : (
          <DataStatus text="Tidak ada data" />
        )}

        {modalConfig?.show && (
          <ModalDetailKegiatan
            setModalConfig={setModalConfig}
            modalConfig={modalConfig}
            dropdown={dropdown}
          />
        )}

        {showModalFilter && (
          <ModalFilterProgram
            show={showModalFilter}
            setShow={setShowModalFilter}
            data={dataFilter}
            setData={setDataFilter}
            dropdown={dropdown}
          />
        )}
      </CRUDLayout>
    </>
  );
};
