import Services from "../../services";

class PPAApi {
  dropdownUnitOrganisasi() {
    return Services.get("/unit_organisasi/dropdown");
  }

  dropdownKategoriAnggaran() {
    return Services.get("/kategori_anggaran/dropdown");
  }

  dropdownItemSumberDayaBuaso(params) {
    return Services.get("/dropdown/buaso", { params });
  }

  dropdownItemSumberDaya() {
    return Services.get("/item_sumber_daya");
  }

  dropdownItemAset(params) {
    return Services.get("/dropdown/aset", { params });
  }

  dropdownSumberPengadaan() {
    return Services.get("/sumber_pengadaan/dropdown");
  }

  dropdownKaryawan() {
    return Services.get("/karyawan/dropdown");
  }

  dropdownJenisAnggaran() {
    return Services.get("/jenis_anggaran/dropdown");
  }

  dropdownKelompokAnggaran(params) {
    return Services.get("/kelompok_anggaran/dropdown", { params });
  }

  dropdownSubKelompok(params) {
    return Services.get("/sub_kelompok_anggaran/dropdown", { params });
  }

  getPage(params) {
    return Services.get("/ppa/", { params });
  }

  getPageSubKegiatan(params) {
    return Services.get("/sub_kegiatan/list_available", { params });
  }

  getSingle(params) {
    return Services.get("ppa/single/", { params });
  }

  getDetail(params) {
    return Services.get("detail", { params });
  }

  getKode(params) {
    return Services.get("/ppa/no_baru", { params });
  }

  show(value) {
    return Services.put("/ppa/show", value);
  }

  hide(value) {
    return Services.put("/ppa/hide", value);
  }

  create(value) {
    return Services.post("/ppa", value);
  }

  update(value) {
    return Services.put("/ppa", value);
  }

  delete(value) {
    return Services.post("/ppa/delete", value);
  }
}

export default new PPAApi();
