import { KategoriAnggaranApi } from "api";
import { ActionButton, Input, TextArea } from "components";
import { useFormikContext } from "formik";
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

export const ModalAction = ({ setModalConfig, modalConfig }) => {
  const {
    values,
    handleChange,
    setValues,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
    resetForm,
  } = useFormikContext();

  return (
    <div>
      <Modal
        show={modalConfig?.show}
        onHide={() => setModalConfig({ data: {}, type: "add", show: false })}
      >
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            {modalConfig.type === "edit" ? "Ubah" : "Tambah"} Kategori Anggaran
          </Modal.Header>
          <Modal.Body>
            <Input
              label="Nama Kategori Anggaran"
              placeholder="Masukan nama jenis anggaran"
              name="namaKategori"
              value={values?.namaKategori}
              onChange={handleChange}
              error={Boolean(errors.namaKategori) && touched.namaKategori}
              errorText={
                Boolean(errors.namaKategori) &&
                touched.namaKategori &&
                errors.namaKategori
              }
            />
            <TextArea
              label="Keterangan"
              placeholder="Masukan keterangan"
              name="keterangan"
              rows="4"
              value={values?.keterangan}
              onChange={handleChange}
            />
          </Modal.Body>
          <Modal.Footer>
            {/* <ActionButton
              text="Batal"
              variant="outline-secondary"
              size="sm"
              onClick={() =>
                resetForm({
                  values: { namaKategori: "", keterangan: "" },
                })
              }
            /> */}
            <ActionButton
              text={modalConfig.type === "edit" ? "Ubah" : "Simpan"}
              type="submit"
              size="sm"
              variant={modalConfig.type === "edit" ? "success" : "primary"}
              loading={isSubmitting}
            />
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};
