// Dashboard
import Dashboard from "pages/Dashboard";
import {
  ListItemSumberDaya,
  ListJenisAnggaran,
  ListKategoriAnggaran,
  ListKelompokAnggaran,
  ListMappingCoaItemSumberDaya,
  ListSubKelompokAnggaran,
  ListSumberPengadaan,
} from "pages/Master";
import {
  DetailKegiatan,
  DetailPPA,
  DetailRealisasiPPA,
  DetailSubKegiatan,
  ListKegiatan,
  ListKegiatanSubKegiatan,
  ListPPA,
  ListPPARealisasiPPA,
  ListProgram,
  ListProgramKegiatan,
  ListRealisasiPPA,
  ListSubKegiatan,
  ListSubKegiatanPPA,
  TambahKegiatan,
  TambahPPA,
  TambahRealisasiPPA,
  TambahSubKegiatan,
  UbahKegiatan,
  UbahPPA,
  UbahRealisasiPPA,
  UbahSubKegiatan,
} from "pages/Transaksi";
/* 
  MENU REQUIREMENTS
    > EXACT = OPTIONAL (TRUE/FALSE)
    > PATH  = REQUIRED
    > PAGE  = REQUIRED
    > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
*/

export default [
  // Dashboard
  {
    exact: true,
    path: "/",
    page: Dashboard,
    hak: ["SUPA", "ANG"],
  },

  // MASTER

  // JENIS ANGGARAN
  {
    exact: true,
    path: "/master/jenis-anggaran",
    page: ListJenisAnggaran,
    hak: ["SUPA", "ANG"],
  },
  // KELOMPOK ANGGARAN
  {
    exact: true,
    path: "/master/kelompok-anggaran",
    page: ListKelompokAnggaran,
    hak: ["SUPA", "ANG"],
  },
  // SUB KELOMPOK ANGGARAN
  {
    exact: true,
    path: "/master/sub-kelompok-anggaran",
    page: ListSubKelompokAnggaran,
    hak: ["SUPA", "ANG"],
  },
  // KATEGORI ANGGARAN
  {
    exact: true,
    path: "/master/kategori-anggaran",
    page: ListKategoriAnggaran,
    hak: ["SUPA", "ANG"],
  },
  // ITEM SUMBER DAYA
  {
    exact: true,
    path: "/master/item-sumber-daya",
    page: ListItemSumberDaya,
    hak: ["SUPA", "ANG"],
  },
  // MAPPING COA ITEM SUMBER DAYA
  {
    exact: true,
    path: "/master/mapping-coa-item",
    page: ListMappingCoaItemSumberDaya,
    hak: ["SUPA", "ANG"],
  },
  // SUMBER PENGADAAN
  {
    exact: true,
    path: "/master/sumber-pengadaan",
    page: ListSumberPengadaan,
    hak: ["SUPA", "ANG"],
  },

  // TRANSAKSI
  // PROGRAM
  {
    exact: true,
    path: "/transaksi/program",
    page: ListProgram,
    hak: ["SUPA", "ANG", "ANG_TRN_PRO"],
  },
  // KEGIATAN
  {
    exact: true,
    path: "/transaksi/kegiatan",
    page: ListKegiatan,
    hak: ["SUPA", "ANG", "ANG_TRN_KEG"],
  },
  {
    exact: true,
    path: "/transaksi/kegiatan/program",
    page: ListProgramKegiatan,
    hak: ["SUPA", "ANG", "ANG_TRN_KEG"],
  },
  {
    exact: true,
    path: "/transaksi/kegiatan/tambah/:id",
    page: TambahKegiatan,
    hak: ["SUPA", "ANG", "ANG_TRN_KEG"],
  },
  {
    exact: true,
    path: "/transaksi/kegiatan/ubah/:id",
    page: UbahKegiatan,
    hak: ["SUPA", "ANG", "ANG_TRN_KEG"],
  },
  {
    exact: true,
    path: "/transaksi/kegiatan/detail/:id",
    page: DetailKegiatan,
    hak: ["SUPA", "ANG", "ANG_TRN_KEG"],
  },

  // SUB KEGIATAN
  {
    exact: true,
    path: "/transaksi/sub-kegiatan",
    page: ListSubKegiatan,
    hak: ["SUPA", "ANG", "ANG_TRN_SKEG"],
  },
  {
    exact: true,
    path: "/transaksi/sub-kegiatan/kegiatan",
    page: ListKegiatanSubKegiatan,
    hak: ["SUPA", "ANG", "ANG_TRN_SKEG"],
  },
  {
    exact: true,
    path: "/transaksi/sub-kegiatan/tambah/:id",
    page: TambahSubKegiatan,
    hak: ["SUPA", "ANG", "ANG_TRN_SKEG"],
  },
  {
    exact: true,
    path: "/transaksi/sub-kegiatan/ubah/:id",
    page: UbahSubKegiatan,
    hak: ["SUPA", "ANG", "ANG_TRN_SKEG"],
  },
  {
    exact: true,
    path: "/transaksi/sub-kegiatan/detail/:id",
    page: DetailSubKegiatan,
    hak: ["SUPA", "ANG", "ANG_TRN_SKEG"],
  },

  // PPA
  {
    exact: true,
    path: "/transaksi/ppa",
    page: ListPPA,
    hak: ["SUPA", "ANG", "ANG_TRN_PPA"],
  },
  {
    exact: true,
    path: "/transaksi/ppa/sub-kegiatan",
    page: ListSubKegiatanPPA,
    hak: ["SUPA", "ANG", "ANG_TRN_PPA"],
  },
  {
    exact: true,
    path: "/transaksi/ppa/tambah/:id",
    page: TambahPPA,
    hak: ["SUPA", "ANG", "ANG_TRN_PPA"],
  },
  {
    exact: true,
    path: "/transaksi/ppa/ubah/:id",
    page: UbahPPA,
    hak: ["SUPA", "ANG", "ANG_TRN_PPA"],
  },
  {
    exact: true,
    path: "/transaksi/ppa/detail/:id",
    page: DetailPPA,
    hak: ["SUPA", "ANG", "ANG_TRN_PPA"],
  },

  // REALISASI PPA
  {
    exact: true,
    path: "/transaksi/realisasi-ppa",
    page: ListRealisasiPPA,
    hak: ["SUPA", "ANG", "ANG_TRN_REA"],
  },
  {
    exact: true,
    path: "/transaksi/realisasi-ppa/ppa",
    page: ListPPARealisasiPPA,
    hak: ["SUPA", "ANG", "ANG_TRN_REA"],
  },
  {
    exact: true,
    path: "/transaksi/realisasi-ppa/tambah/:id",
    page: TambahRealisasiPPA,
    hak: ["SUPA", "ANG", "ANG_TRN_REA"],
  },
  {
    exact: true,
    path: "/transaksi/realisasi-ppa/ubah/:id",
    page: UbahRealisasiPPA,
    hak: ["SUPA", "ANG", "ANG_TRN_REA"],
  },
  {
    exact: true,
    path: "/transaksi/realisasi-ppa/detail/:id",
    page: DetailRealisasiPPA,
    hak: ["SUPA", "ANG", "ANG_TRN_REA"],
  },
];
