import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import {
  DatePicker,
  InfoItemHorizontal,
  Input,
  SelectSearch,
  TextArea,
} from "components";
import { useFormikContext } from "formik";
import Axios from "axios";
import { KegiatanApi } from "api";
import { DateConvert } from "utilities";

const InfoSection = ({ data }) => {
  return (
    <>
      <Row>
        <Col lg="6">
          <InfoItemHorizontal
            label="Tgl. Kegiatan"
            text={
              data.tglKegiatan
                ? DateConvert(new Date(data.tglKegiatan)).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="No. Kegiatan"
            text={data.noKegiatan ?? "-"}
          />
          <InfoItemHorizontal
            label="Nama Kegiatan"
            text={data.namaKegiatan ?? "-"}
          />
          <InfoItemHorizontal
            label="Penanggung Jawab"
            text={data.namaPenanggungJawabKegiatan ?? "-"}
          />
        </Col>
        <Col lg="6">
          <InfoItemHorizontal
            label="Periode Mulai"
            text={
              data.periodeMulaiKegiatan
                ? DateConvert(new Date(data.periodeMulaiKegiatan)).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="Periode Selesai"
            text={
              data.periodeSelesaiKegiatan
                ? DateConvert(new Date(data.periodeSelesaiKegiatan)).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="Lama Periode"
            text={`${data.lamaPeriodeKegiatan ?? "-"} Hari`}
          />
          <InfoItemHorizontal
            label="Deskripsi"
            text={data.deskripsiKegiatan ?? "-"}
          />
        </Col>
      </Row>
      <hr />
      <InfoItemHorizontal
        label="Jenis Anggaran"
        text={data?.namaJenisAnggaran ?? "-"}
      />
      <InfoItemHorizontal
        label="Kelompok Anggaran"
        text={data?.namaKelompokAnggaran ?? "-"}
      />
      <InfoItemHorizontal
        label="Sub Kelompok Anggaran"
        text={data?.namaSubKelompokAnggaran ?? "-"}
      />
    </>
  );
};

export const FormSection = ({ type }) => {
  const { values, setValues, errors, touched } = useFormikContext();
  const [isNomorLoading, setIsNomorLoading] = useState(false);
  const [dropdownPenanggungJawab, setDropdownPenanggungJawab] = useState([]);
  const [dropdownJenisAnggaran, setDropdownJenisAnggaran] = useState([]);
  const [dropdownKelompokAnggaran, setDropdownKelompokAnggaran] = useState([]);
  const [dropdownSubKelompok, setDropdownSubKelompok] = useState([]);
  const [isJenisAnggaranChange, setIsJenisAnggaranChange] = useState({
    loading: false,
    disabled: true,
  });
  const [isKelompokChange, setIsKelompokChange] = useState({
    loading: false,
    disabled: true,
  });

  const getNomorHandler = (tgl) => {
    setIsNomorLoading(true);
    const tanggal = DateConvert(new Date(tgl)).default;

    KegiatanApi.getKode({ tanggal })
      .then(({ data }) => {
        const no = data?.data;
        setValues({
          ...values,
          kegiatan: {
            ...values.kegiatan,
            noKegiatan: no,
            tglKegiatan: tanggal,
          },
        });
      })
      .catch(() => {
        setValues({
          ...values,
          kegiatan: {
            ...values.kegiatan,
            noKegiatan: "",
            tglKegiatan: null,
          },
        });
        window.alert("Nomor gagal dimuat");
      })
      .finally(() => {
        setIsNomorLoading(false);
      });
  };

  const getDropdown = () => {
    Axios.all([
      KegiatanApi.dropdownKaryawan(),
      KegiatanApi.dropdownJenisAnggaran(),
    ]).then(
      Axios.spread((karyawan, jenis) => {
        const mappingDropdownPenanggungJawab = (data) =>
          data.map((val) => ({
            label: val.nama_karyawan,
            value: val.id_karyawan,
          }));

        const mappingDropdownJenisAnggaran = (data) =>
          data.map((val) => ({
            label: val.nama_jenis_anggaran,
            value: val.id_jenis_anggaran,
          }));

        setDropdownPenanggungJawab(
          mappingDropdownPenanggungJawab(karyawan?.data?.data ?? [])
        );
        setDropdownJenisAnggaran(
          mappingDropdownJenisAnggaran(jenis?.data?.data ?? [])
        );
      })
    );
  };

  const jenisAnggaranChange = (data) => {
    setIsJenisAnggaranChange({ disabled: true, loading: true });
    setIsKelompokChange({ disabled: true, loading: true });
    Axios.all([
      KegiatanApi.dropdownKelompokAnggaran({ id_jenis_anggaran: data.value }),
    ]).then(
      Axios.spread((kelompok) => {
        const mappingDropdownKelompok = (data) =>
          data.map((val) => ({
            label: val.nama_kelompok_anggaran,
            value: val.id_kelompok_anggaran,
          }));

        setDropdownKelompokAnggaran(
          mappingDropdownKelompok(kelompok?.data?.data)
        );
        setValues({
          ...values,
          kegiatan: {
            ...values.kegiatan,
            idJenisAnggaran: data.value,
            namaJenisAnggaran: data.label,
            idKelompokAnggaran: null,
            namaKelompokAnggaran: "",
            idSubKelompokAnggaran: null,
            namaSubKelompokAnggaran: "",
          },
        });
        setIsJenisAnggaranChange({ disabled: false, loading: false });
        setIsKelompokChange({ disabled: true, loading: false });
      })
    );
  };

  const kelompokAnggaranChange = (data) => {
    setIsKelompokChange({ ...isKelompokChange, loading: true });
    Axios.all([
      KegiatanApi.dropdownSubKelompok({
        id_kelompok_anggaran: data.value,
      }),
    ]).then(
      Axios.spread((kelompok) => {
        const mappingDropdownSubKelompok = (data) =>
          data.map((val) => ({
            label: val.nama_sub_kelompok_anggaran,
            value: val.id_sub_kelompok_anggaran,
          }));

        setDropdownSubKelompok(
          mappingDropdownSubKelompok(kelompok?.data?.data)
        );
        setValues({
          ...values,
          kegiatan: {
            ...values.kegiatan,
            idKelompokAnggaran: data.value,
            namaKelompokAnggaran: data.label,
            idSubKelompokAnggaran: null,
            namaSubKelompokAnggaran: "",
          },
        });
        setIsKelompokChange({ disabled: false, loading: false });
      })
    );
  };

  useEffect(() => {
    type !== "read" && getDropdown();

    return () => {};
  }, []);

  return type === "read" ? (
    <InfoSection data={values?.kegiatan} />
  ) : (
    <Row>
      <Col lg="6">
        <DatePicker
          label="Tgl. Kegiatan"
          dateFormat='dd/MM/yyyy'
          onChange={(date) => {
            getNomorHandler(date);
          }}
          selected={
            values?.kegiatan?.tglKegiatan
              ? new Date(values?.kegiatan?.tglKegiatan)
              : null
          }
          error={
            Boolean(errors?.kegiatan?.tglKegiatan) &&
            touched?.kegiatan?.tglKegiatan
          }
          errorText={
            Boolean(errors?.kegiatan?.tglKegiatan) &&
            touched?.kegiatan?.tglKegiatan &&
            errors?.kegiatan?.tglKegiatan
          }
        />
      </Col>
      <Col lg="6">
        <Input
          label="No. Kegiatan"
          disabled
          value={
            isNomorLoading ? "Memuat Data.." : values?.kegiatan?.noKegiatan
          }
          placeholder="Pilih tanggal terlebih dahulu"
        />
      </Col>
      <Col lg="6">
        <Input
          label="Nama Kegiatan"
          name="namaKegiatan"
          type="text"
          placeholder="Masukan nama program"
          onChange={(e) =>
            setValues({
              ...values,
              kegiatan: { ...values.kegiatan, namaKegiatan: e?.target?.value },
            })
          }
          value={values?.kegiatan?.namaKegiatan}
          error={
            Boolean(errors?.kegiatan?.namaKegiatan) &&
            touched?.kegiatan?.namaKegiatan
          }
          errorText={
            Boolean(errors?.kegiatan?.namaKegiatan) &&
            touched?.kegiatan?.namaKegiatan &&
            errors?.kegiatan?.namaKegiatan
          }
        />
        <DatePicker
          label="Periode Mulai"
          dateFormat='dd/MM/yyyy'
          onChange={(date) =>
            setValues({
              ...values,
              kegiatan: {
                ...values.kegiatan,
                periodeMulaiKegiatan: DateConvert(new Date(date)).default,
              },
            })
          }
          selected={
            values?.kegiatan?.periodeMulaiKegiatan
              ? new Date(values?.kegiatan?.periodeMulaiKegiatan)
              : null
          }
          error={
            Boolean(errors?.kegiatan?.periodeMulaiKegiatan) &&
            touched?.kegiatan?.periodeMulaiKegiatan
          }
          errorText={
            Boolean(errors?.kegiatan?.periodeMulaiKegiatan) &&
            touched?.kegiatan?.periodeMulaiKegiatan &&
            errors?.kegiatan?.periodeMulaiKegiatan
          }
        />
      </Col>
      <Col lg="6">
        <SelectSearch
          key={values?.kegiatan?.idPenanggungJawabKegiatan}
          label="Penanggung Jawab"
          placeholder="Pilih penanggung jawab"
          option={dropdownPenanggungJawab}
          onChange={(res) =>
            setValues({
              ...values,
              kegiatan: {
                ...values.kegiatan,
                idPenanggungJawabKegiatan: res.value,
                namaPenanggungJawabKegiatan: res.label,
              },
            })
          }
          defaultValue={
            values?.kegiatan?.idPenanggungJawabKegiatan && {
              value: values?.kegiatan?.idPenanggungJawabKegiatan,
              label: values?.kegiatan?.namaPenanggungJawabKegiatan,
            }
          }
          error={
            Boolean(errors?.kegiatan?.idPenanggungJawabKegiatan) &&
            touched?.kegiatan?.idPenanggungJawabKegiatan
          }
          errorText={
            Boolean(errors?.kegiatan?.idPenanggungJawabKegiatan) &&
            touched?.kegiatan?.idPenanggungJawabKegiatan &&
            errors?.kegiatan?.idPenanggungJawabKegiatan
          }
        />
        <DatePicker
          label="Periode Selesai"
          dateFormat='dd/MM/yyyy'
          onChange={(date) =>
            setValues({
              ...values,
              kegiatan: {
                ...values.kegiatan,
                periodeSelesaiKegiatan: DateConvert(new Date(date)).default,
              },
            })
          }
          selected={
            values?.kegiatan?.periodeSelesaiKegiatan
              ? new Date(values?.kegiatan?.periodeSelesaiKegiatan)
              : null
          }
          error={
            Boolean(errors?.kegiatan?.periodeSelesaiKegiatan) &&
            touched?.kegiatan?.periodeSelesaiKegiatan
          }
          errorText={
            Boolean(errors?.kegiatan?.periodeSelesaiKegiatan) &&
            touched?.kegiatan?.periodeSelesaiKegiatan &&
            errors?.kegiatan?.periodeSelesaiKegiatan
          }
        />
      </Col>
      <Col lg="4">
        <SelectSearch
          label="Jenis Anggaran"
          placeholder="Pilih jenis anggaran"
          option={dropdownJenisAnggaran}
          onChange={(val) => jenisAnggaranChange(val)}
          defaultValue={
            values?.kegiatan?.idJenisAnggaran && {
              value: values?.kegiatan?.idJenisAnggaran,
              label: values?.kegiatan?.namaJenisAnggaran,
            }
          }
          error={
            Boolean(errors?.kegiatan?.idJenisAnggaran) &&
            touched?.kegiatan?.idJenisAnggaran
          }
          errorText={
            Boolean(errors?.kegiatan?.idJenisAnggaran) &&
            touched?.kegiatan?.idJenisAnggaran &&
            errors?.kegiatan?.idJenisAnggaran
          }
        />
      </Col>
      <Col lg="4">
        <SelectSearch
          label="Kelompok Anggaran"
          placeholder="Pilih kelompok anggaran"
          isDisabled={Boolean(!values?.kegiatan?.idJenisAnggaran)}
          loading={isJenisAnggaranChange.loading}
          option={dropdownKelompokAnggaran}
          onChange={(val) => kelompokAnggaranChange(val)}
          defaultValue={
            values?.kegiatan?.idKelompokAnggaran && {
              value: values?.kegiatan?.idKelompokAnggaran,
              label: values?.kegiatan?.namaKelompokAnggaran,
            }
          }
          error={
            Boolean(errors?.kegiatan?.idKelompokAnggaran) &&
            touched?.kegiatan?.idKelompokAnggaran
          }
          errorText={
            Boolean(errors?.kegiatan?.idKelompokAnggaran) &&
            touched?.kegiatan?.idKelompokAnggaran &&
            errors?.kegiatan?.idKelompokAnggaran
          }
        />
      </Col>
      <Col lg="4">
        <SelectSearch
          label="Sub Kelompok Anggaran"
          placeholder="Pilih sub kelompok anggaran"
          isDisabled={Boolean(!values?.kegiatan?.idKelompokAnggaran)}
          loading={isKelompokChange.loading}
          option={dropdownSubKelompok}
          onChange={(res) =>
            setValues({
              ...values,
              kegiatan: {
                ...values.kegiatan,
                idSubKelompokAnggaran: res.value,
                namaSubKelompokAnggaran: res.label,
              },
            })
          }
          defaultValue={
            values?.kegiatan?.idSubKelompokAnggaran && {
              value: values?.kegiatan?.idSubKelompokAnggaran,
              label: values?.kegiatan?.namaSubKelompokAnggaran,
            }
          }
          error={
            Boolean(errors?.kegiatan?.idSubKelompokAnggaran) &&
            touched?.kegiatan?.idSubKelompokAnggaran
          }
          errorText={
            Boolean(errors?.kegiatan?.idSubKelompokAnggaran) &&
            touched?.kegiatan?.idSubKelompokAnggaran &&
            errors?.kegiatan?.idSubKelompokAnggaran
          }
        />
      </Col>
      <Col lg="12">
        <TextArea
          label="Deskripsi"
          rows="4"
          placeholder="Masukan deskripsi"
          name="deskripsiKegiatan"
          onChange={(e) =>
            setValues({
              ...values,
              kegiatan: {
                ...values?.kegiatan,
                deskripsiKegiatan: e?.target?.value,
              },
            })
          }
          value={values?.kegiatan?.deskripsiKegiatan}
          error={
            Boolean(errors?.kegiatan?.deskripsiKegiatan) &&
            touched?.kegiatan?.deskripsiKegiatan
          }
          errorText={
            Boolean(errors?.kegiatan?.deskripsiKegiatan) &&
            touched?.kegiatan?.deskripsiKegiatan &&
            errors?.kegiatan?.deskripsiKegiatan
          }
        />
      </Col>
    </Row>
  );
};
