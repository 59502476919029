import React, { useState, useEffect } from "react";
import {
  Table,
  Th,
  Td,
  DataStatus,
  TdFixed,
  InputQtyDecimal,
  ThFixed,
  Tr,
  THead,
  TBody,
  ActionButton,
  SelectSearch,
  CreateButton,
  UpdateButton,
  DeleteButton,
  NumberFormat,
} from "components";
import { useLocation } from "react-router-dom";
import { Formik, useFormikContext } from "formik";

import { ButtonGroup } from "react-bootstrap";
import { AiOutlineDownload, AiOutlineEye } from "react-icons/ai";
import { DecimalConvert, RupiahConvert } from "utilities";
import { ModalUpload } from "../Comps";

export const TableItemSumberDayaSection = ({ dropdown, type, tabType }) => {
  console.log(type);
  const location = useLocation();
  const [update, setUpdate] = useState("");
  const [modalUploadConfig, setModalUploadConfig] = useState({
    show: false,
    index: null,
    data: {},
  });
  const { values, setValues } = useFormikContext();
  const checkKeyTab =
    tabType === "material"
      ? "detailMaterial"
      : tabType === "manusia"
      ? "detailManusia"
      : tabType === "peralatan"
      ? "detailPeralatan"
      : tabType === "overhead"
      ? "detailOverhead"
      : "detailLainnya";

  return (
    <>
      <Table>
        <THead>
          <Tr>
            <Th>No</Th>
            <Th>Item Sumber Daya</Th>
            <Th>Satuan</Th>
            <Th>Qty.</Th>
            {type !== "read" && (
              <>
                <Th>Qty. Terealisasi</Th>
                <Th>Sisa</Th>
              </>
            )}
            <Th>Qty. Realisasi</Th>
            <Th>Harga Satuan PPA</Th>
            <Th>Sub Total</Th>
            {type !== "read" && (
              <>
                <Th>Nominal Terealisasi</Th>
                <Th>Sisa</Th>
              </>
            )}
            <Th>Nominal Realisasi</Th>
            <ThFixed>Bukti</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {values?.subKegiatan?.[checkKeyTab]?.length > 0 &&
            values?.subKegiatan?.[checkKeyTab]?.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <Td>{val.namaItemSumberDaya ?? "-"}</Td>
                <Td>{val.namaSatuan ?? "-"}</Td>
                <Td className="text-right">
                  {DecimalConvert(parseFloat(val.qtyPPA ?? 0)).getWithComa}
                </Td>
                {type !== "read" && (
                  <>
                    <Td className="text-right">
                      {
                        DecimalConvert(parseFloat(val.qtyTerealisasi ?? 0))
                          .getWithComa
                      }
                    </Td>
                    <Td className="text-right">
                      {
                        DecimalConvert(
                          parseFloat(val.qty ?? 0) -
                            parseFloat(val.qtyTerealisasi ?? 0)
                        ).getWithComa
                      }
                    </Td>
                  </>
                )}
                <Td className="text-right">
                  {type !== "read" ? (
                    <InputQtyDecimal
                      placeholder="Masukan qty"
                      value={val?.qtyRealisasi}
                      onChange={(e) => {
                        const qtySisa =
                          parseFloat(val.qty ?? 0) -
                          parseFloat(val.qtyTerealisasi ?? 0);

                        setValues({
                          ...values,
                          subKegiatan: {
                            ...values.subKegiatan,
                            [checkKeyTab]: values?.subKegiatan?.[
                              checkKeyTab
                            ]?.map((res, i) =>
                              index === i
                                ? {
                                    ...res,
                                    qtyRealisasi:
                                      qtySisa < parseFloat(e) ? qtySisa : e,
                                  }
                                : res
                            ),
                          },
                        });
                      }}
                    />
                  ) : (
                    DecimalConvert(parseFloat(val.qtyRealisasi ?? 0))
                      .getWithComa
                  )}
                </Td>
                <Td className="text-right">
                  {
                    RupiahConvert(String(parseFloat(val.hargaSatuanPPA ?? 0)))
                      .getWithComa
                  }
                </Td>
                <Td className="text-right">
                  {
                    RupiahConvert(
                      String(
                        parseFloat(val.hargaSatuanPPA ?? 0) *
                          parseFloat(val.qtyPPA ?? 0)
                      )
                    ).getWithComa
                  }
                </Td>
                {type !== "read" && (
                  <>
                    <Td className="text-right">
                      {
                        RupiahConvert(
                          String(parseFloat(val.nominalTerealisasi ?? 0))
                        ).getWithComa
                      }
                    </Td>
                    <Td className="text-right">
                      {
                        RupiahConvert(
                          String(
                            parseFloat(val.hargaSatuanPPA ?? 0) *
                              parseFloat(val.qtyPPA ?? 0) -
                              parseFloat(val.nominalTerealisasi ?? 0)
                          )
                        ).getWithComa
                      }
                    </Td>
                  </>
                )}
                <Td className="text-right">
                  {type !== "read" ? (
                    <NumberFormat
                      placeholder="Masukan nominal terealisasi"
                      value={val.nominalRealisasi}
                      decimalSeparator=","
                      onChange={(e) => {
                        const nominalSisa =
                          parseFloat(val.hargaSatuanPPA ?? 0) *
                            parseFloat(val.qtyPPA ?? 0) -
                          parseFloat(val.nominalTerealisasi ?? 0);
                        setValues({
                          ...values,
                          subKegiatan: {
                            ...values.subKegiatan,
                            [checkKeyTab]: values?.subKegiatan?.[
                              checkKeyTab
                            ]?.map((res, i) =>
                              index === i
                                ? {
                                    ...res,
                                    nominalRealisasi:
                                      nominalSisa < parseFloat(e)
                                        ? nominalSisa
                                        : e,
                                  }
                                : res
                            ),
                          },
                        });
                      }}
                    />
                  ) : (
                    RupiahConvert(
                      parseFloat(val.nominalRealisasi ?? 0).toString()
                    ).getWithComa
                  )}
                </Td>
                {/* {type !== "read" && ( */}
                <TdFixed>
                  <ButtonGroup>
                    <ActionButton
                      text={
                        type === "read" ? (
                          <AiOutlineEye />
                        ) : (
                          <AiOutlineDownload />
                        )
                      }
                      onClick={() =>
                        setModalUploadConfig({
                          show: true,
                          index: index,
                          data: val,
                        })
                      }
                      size="sm"
                    />
                  </ButtonGroup>
                </TdFixed>
                {/* )} */}
              </Tr>
            ))}
        </TBody>
      </Table>
      <ModalUpload
        setModalConfig={setModalUploadConfig}
        modalConfig={modalUploadConfig}
        tabKey={checkKeyTab}
        readOnly={type === "read" ? true : false}
      />
    </>
  );
};
