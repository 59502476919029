import { KelompokAnggaranApi } from "api";
import { ActionButton, Input, SelectSearch, TextArea } from "components";
import { useFormikContext } from "formik";
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

export const ModalAction = ({ setModalConfig, modalConfig, dropdown }) => {
  const [isNomorLoading, setIsNomorLoading] = useState(false);
  const {
    values,
    handleChange,
    setValues,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
    resetForm,
  } = useFormikContext();

  const getNomorHandler = () => {
    setIsNomorLoading(true);

    KelompokAnggaranApi.getKode()
      .then(({ data }) => {
        const kode = data.data;
        if (modalConfig.type === "add") {
          setValues({
            ...values,
            kodeKelompok: kode,
          });
        }
      })
      .catch(() => {
        setValues({
          ...values,
          kodeKelompok: "",
        });
        window.alert("Nomor gagal dimuat");
      })
      .finally(() => {
        setIsNomorLoading(false);
      });
  };

  useEffect(() => {
    getNomorHandler();

    return () => {
      setIsNomorLoading(false);
    };
  }, []);

  return (
    <div>
      <Modal
        show={modalConfig?.show}
        onHide={() => setModalConfig({ data: {}, type: "add", show: false })}
      >
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            {modalConfig.type === "edit" ? "Ubah" : "Tambah"} Item Sumber Daya
          </Modal.Header>
          <Modal.Body>
            <Input
              label="Nama Item Sumber Daya"
              placeholder="Masukan nama kelompok anggaran"
              name="namaItem"
              value={values?.namaItem}
              onChange={handleChange}
              error={Boolean(errors.namaItem) && touched.namaItem}
              errorText={
                Boolean(errors.namaItem) && touched.namaItem && errors.namaItem
              }
            />

            <SelectSearch
              label="Satuan"
              placeholder="Pilih satuan"
              option={dropdown?.satuan}
              loading={isNomorLoading}
              onChange={(val) =>
                setValues({
                  ...values,
                  idSatuan: val?.value,
                  namaSatuan: val?.label,
                })
              }
              defaultValue={
                values?.idSatuan && {
                  value: values.idSatuan,
                  label: values.namaSatuan,
                }
              }
              error={Boolean(errors.idSatuan) && touched.idSatuan}
              errorText={
                Boolean(errors.idSatuan) && touched.idSatuan && errors.idSatuan
              }
            />

            <SelectSearch
              label="Kategori Anggaran"
              placeholder="Pilih kategori anggaran"
              option={dropdown?.kategori}
              loading={isNomorLoading}
              onChange={(val) =>
                setValues({
                  ...values,
                  idKategori: val?.value,
                  namaKategori: val?.label,
                })
              }
              defaultValue={
                values?.idKategori && {
                  value: values.idKategori,
                  label: values.namaKategori,
                }
              }
              error={Boolean(errors.idKategori) && touched.idKategori}
              errorText={
                Boolean(errors.idKategori) &&
                touched.idKategori &&
                errors.idKategori
              }
            />

            <TextArea
              label="Keterangan"
              placeholder="Masukan keterangan"
              name="keterangan"
              rows="4"
              value={values?.keterangan}
              onChange={handleChange}
            />
          </Modal.Body>
          <Modal.Footer>
            {/* <ActionButton
              text="Batal"
              variant="outline-secondary"
              size="sm"
              onClick={() =>
                resetForm({
                  values: {
                    keterangan: "",
                    idKelompok: "",
                    namaItem: "",
                    idKategori: "",
                    namaKategori: "",
                  },
                })
              }
            /> */}
            <ActionButton
              text={modalConfig.type === "edit" ? "Ubah" : "Simpan"}
              type="submit"
              size="sm"
              variant={modalConfig.type === "edit" ? "success" : "primary"}
              loading={isSubmitting}
            />
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};
