import { InfoItemHorizontal } from "components";
import { useFormikContext } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { DateConvert } from "utilities";

export const InfoSection = () => {
  const { values } = useFormikContext();
  return (
    <Row>
      <Col lg="6">
        <InfoItemHorizontal
          label="Tgl. Program"
          text={
            values.tglProgram
              ? DateConvert(new Date(values.tglProgram)).detail
              : "-"
          }
        />
        <InfoItemHorizontal
          label="No. Program"
          text={values.noProgram ?? "-"}
        />
        <InfoItemHorizontal
          label="Nama Program"
          text={values.namaProgram ?? "-"}
        />
        <InfoItemHorizontal
          label="Unit Organisasi"
          text={values.namaUnitOrganisasi ?? "-"}
        />
        <InfoItemHorizontal
          label="Unit Produksi"
          text={values.namaUnitProduksi ?? "-"}
        />
      </Col>
      <Col lg="6">
        <InfoItemHorizontal
          label="Penanggung Jawab"
          text={values.namaPenanggungJawab ?? "-"}
        />
        <InfoItemHorizontal
          label="Periode Mulai"
          text={
            values.periodeMulai
              ? DateConvert(new Date(values.periodeMulai)).detail
              : "-"
          }
        />
        <InfoItemHorizontal
          label="Periode Selesai"
          text={
            values.periodeSelesai
              ? DateConvert(new Date(values.periodeSelesai)).detail
              : "-"
          }
        />
        <InfoItemHorizontal
          label="Lama Periode"
          text={`${values.lamaPeriode ?? "-"} Hari`}
        />
        <InfoItemHorizontal label="Deskripsi" text={values.deskripsi ?? "-"} />
      </Col>
    </Row>
  );
};
