import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Formik } from "formik";

import {
  CRUDLayout,
  Input,
  InputSearch,
  // ExportButton,
  CreateButton,
  ActionButton,
  DataStatus,
  UpdateButton,
  DeleteButton,
  Switch,
  Pagination,
  Select,
  Alert,
  Th,
  Td,
  Modal,
} from "components";
import { KelompokAnggaranApi } from "api";
import { TableNumber } from "utilities";
import { ModalAction } from "./Comps";
import {
  formInitialValues,
  formSubmmitMapper,
  formValidationSchema,
} from "./Utils";

export const ListKelompokAnggaran = ({ setNavbarTitle }) => {
  // Title
  const title = "KELOMPOK ANGGARAN";

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(true);

  // STATE SEARCHING
  const [searchKey, setSearchKey] = useState("");

  // STATE DATA KELOMPOK
  const [data, setData] = useState([]);
  const [dropdownJenisAnggaran, setDropdownJenisAnggaran] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  });

  const [modalConfig, setModalConfig] = useState({
    type: "add",
    show: false,
    data: {},
  });

  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
    show: false,
  });

  const [searchTime, setSearchTime] = useState(null);

  const mappingDropdownJenisAnggaran = (data) =>
    data.map((val) => ({
      label: val?.nama_jenis_anggaran ?? "",
      value: val?.id_jenis_anggaran ?? "",
    }));

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true);

    Axios.all([
      KelompokAnggaranApi.getPage({
        q: searchKey,
        page: paginationConfig.page,
        per_page: paginationConfig.dataLength,
      }),
      KelompokAnggaranApi.dropdownJenisAnggaran(),
    ])
      .then(
        Axios.spread((res, jenis) => {
          setData(res.data.data);
          setDropdownJenisAnggaran(
            mappingDropdownJenisAnggaran(jenis?.data?.data)
          );
          setPaginationConfig({
            ...paginationConfig,
            dataCount: res?.data?.data_count,
            totalPage: res?.data?.total_page,
          });
        })
      )
      .catch((err) => alert(err))
      .finally(() => {
        if (searchKey != "") {
          setAlertConfig({
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
            show: true,
          });
        }
        setIsLoading(false);
      });
  };

  // FUNCTION SWITCH HIDDEN DATA
  const changeDataStatus = (status, id) => {
    setIsLoading(true);
    setAlertConfig({ ...alertConfig, show: false });

    const value = {
      id_kelompok_anggaran: id,
    };

    const onLoadedSuccess = () => {
      setAlertConfig({
        variant: "primary",
        text: "Ubah status data berhasil",
        show: true,
      });
    };

    const onLoadedFailed = () => {
      setAlertConfig({
        variant: "danger",
        text: "Ubah status data gagal",
        show: true,
      });
    };

    status === true
      ? KelompokAnggaranApi.show(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData())
      : KelompokAnggaranApi.hide(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData());
  };

  const onInputSearch = (text) => {
    const key = text.target.value;
    if (searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setSearchKey(key);
        setAlertConfig({
          variant: "primary",
          text: `Hasil Pencarian : ${key}`,
          show: key === "" ? false : true,
        });
      }, 750)
    );
  };

  const formSubmitHandler = (value, { setSubmitting, resetForm }) => {
    setIsLoading(true);
    if (modalConfig.type === "edit") {
      KelompokAnggaranApi.update(formSubmmitMapper(value))
        .then(() => {
          setAlertConfig({
            show: true,
            text: "Data berhasil diubah !!",
            variant: "primary",
          });
        })
        .catch(() => {
          setAlertConfig({
            show: true,
            text: "Data gagal disimpan !!",
            variant: "danger",
          });
        })
        .finally(() => {
          resetForm({
            values: {
              keterangan: "",
              idKelompok: "",
              namaKelompok: "",
              idJenis: "",
              namaJenis: "",
            },
          });
          setModalConfig({ data: {}, type: "add", show: false });
          setIsLoading(false);
          setSubmitting(false);
          getData();
        });
    }

    if (modalConfig.type === "add") {
      KelompokAnggaranApi.create(formSubmmitMapper(value))
        .then(() => {
          setAlertConfig({
            show: true,
            text: "Data berhasil disimpan !!",
            variant: "primary",
          });
        })
        .catch(() => {
          setAlertConfig({
            show: true,
            text: "Data gagal disimpan !!",
            variant: "danger",
          });
        })
        .finally(() => {
          resetForm({
            values: {
              keterangan: "",
              idKelompok: "",
              namaKelompok: "",
              idJenis: "",
              namaJenis: "",
            },
          });
          setModalConfig({ data: {}, type: "add", show: false });
          setIsLoading(false);
          setSubmitting(false);
          getData();
        });
    }
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title); // SET JUDUL NAVBAR
    getData(); // GET DATA DARI SERVER

    return () => {
      setIsLoading(false);
    };
  }, [
    setNavbarTitle,
    paginationConfig?.page,
    paginationConfig?.dataLength,
    searchKey,
  ]);

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <thead>
            <tr className="text-center">
              <Th>No.</Th>
              <Th>Aksi</Th>
              <Th>Kode Kelompok Anggaran</Th>
              <Th>Nama Kelompok Anggaran</Th>
              <Th>Jenis Anggaran</Th>
              <Th>Keterangan</Th>
            </tr>
          </thead>
          <tbody>
            {data.map((val, index) => {
              return (
                <tr key={val.id_kelompok_anggaran}>
                  <Td className="text-center" width="20px">
                    {TableNumber(
                      paginationConfig.page,
                      paginationConfig.dataLength,
                      index
                    )}
                  </Td>
                  <Td width="100px">
                    <div className="d-flex justify-content-center">
                      <UpdateButton
                        size="sm"
                        className="m-1"
                        onClick={() => {
                          setModalConfig({
                            show: true,
                            type: "edit",
                            data: val,
                          });
                        }}
                      />
                      {/* <DeleteButton
                        size="sm"
                        className="m-1"
                        onClick={() => {}}
                      /> */}

                      <Switch
                        id={val.id_kelompok_anggaran}
                        checked={val.is_hidden ? false : true}
                        wrapperClassName="m-1"
                        onChange={() =>
                          changeDataStatus(
                            val.is_hidden,
                            val.id_kelompok_anggaran
                          )
                        }
                      />
                    </div>
                  </Td>
                  <Td>{val.kode_kelompok_anggaran ?? "-"}</Td>
                  <Td>{val.nama_kelompok_anggaran ?? "-"}</Td>
                  <Td>{val.nama_jenis_anggaran ?? "-"}</Td>
                  <Td>{val.keterangan ?? "-"}</Td>
                </tr>
              );
            })}
          </tbody>
        </CRUDLayout.Table>
        {data.length > 0 && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <>
      <CRUDLayout>
        <CRUDLayout.Head>
          <CRUDLayout.HeadSearchSection>
            <div className="d-flex mb-3">
              <InputSearch
                onChange={(e) => {
                  onInputSearch(e);
                }}
              />
            </div>
          </CRUDLayout.HeadSearchSection>

          {/* Button Section */}
          <CRUDLayout.HeadButtonSection>
            <CreateButton
              onClick={() =>
                setModalConfig({
                  type: "add",
                  show: true,
                  data: {},
                })
              }
            />
          </CRUDLayout.HeadButtonSection>
        </CRUDLayout.Head>

        {/* Alert */}
        <Alert
          show={alertConfig.show}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setAlertConfig({ ...alertConfig, show: false })}
        />

        {isLoading ? (
          <DataStatus loading={isLoading} text="Memuat Data" />
        ) : !Array.isArray(data) ? (
          <DataStatus text="Tidak dapat memuat data" />
        ) : data.length > 0 ? (
          <>
            <b>List Data Kelompok Anggaran</b>
            <Table />
          </>
        ) : (
          <DataStatus text="Tidak ada data" />
        )}

        <Formik
          enableReinitialize
          initialValues={formInitialValues(modalConfig?.data)}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {modalConfig?.show && (
            <ModalAction
              setModalConfig={setModalConfig}
              modalConfig={modalConfig}
              dropdown={dropdownJenisAnggaran}
            />
          )}
        </Formik>
      </CRUDLayout>
    </>
  );
};
