import { TBody, Table, Th, Tr, Td, ThFixed, TdFixed } from "components";
import Thead from "components/Table/THead";
import { useFormikContext } from "formik";
import React from "react";
import { RupiahConvert } from "utilities";

export const SummarySection = () => {
  const { values } = useFormikContext();

  const subTotalData = (data) =>
    data.reduce((acc, curr) => {
      const total =
        parseFloat(curr?.qty ?? 0) * parseFloat(curr?.hargaSatuan ?? 0);
      return parseFloat(acc) + parseFloat(total);
    }, 0);

  const dataSummary = [
    {
      label: "Sumber Daya Material",
      value: subTotalData(values?.subKegiatan?.detailMaterial ?? []),
    },
    {
      label: "Sumber Daya Manusia",
      value: subTotalData(values?.subKegiatan?.detailManusia ?? []),
    },
    {
      label: "Sumber Daya Peralatan",
      value: subTotalData(values?.subKegiatan?.detailPeralatan ?? []),
    },
    {
      label: "Sumber Daya Overhead",
      value: subTotalData(values?.subKegiatan?.detailOverhead ?? []),
    },
    {
      label: "Sumber Daya Lainnya",
      value: subTotalData(values?.subKegiatan?.detailLainnya ?? []),
    },
  ];

  const grandTotal = dataSummary?.reduce(
    (acc, curr) => parseFloat(acc) + parseFloat(curr?.value ?? 0),
    0
  );
  return (
    <div>
      <Table>
        <Thead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th>Sumber Daya</Th>
            <ThFixed>Jumlah</ThFixed>
          </Tr>
        </Thead>
        <TBody>
          {dataSummary.map((val, ind) => (
            <Tr key={ind}>
              <TdFixed className="text-center">{ind + 1}</TdFixed>
              <Td>{val.label ?? "-"}</Td>
              <Td className="text-right">
                {
                  RupiahConvert(parseFloat(val.value ?? 0).toString())
                    .getWithComa
                }
              </Td>
            </Tr>
          ))}
          <Tr>
            <Td className="text-right" colSpan="2">
              <b>Total</b>
            </Td>
            <Td className="text-right">
              <b>
                {
                  RupiahConvert(parseFloat(grandTotal ?? 0).toString())
                    .getWithComa
                }
              </b>
            </Td>
          </Tr>
        </TBody>
      </Table>
    </div>
  );
};
