import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import {
  FormSection,
  InfoKegiatanSection,
  InfoProgramSection,
  SummarySection,
} from "./Section";
import {
  formInitialValues,
  formSubmmitMapper,
  formValidationSchema,
} from "./Utils";
import { Formik } from "formik";
import { SubKegiatanApi } from "api";
import { useParams, useHistory } from "react-router-dom/cjs/react-router-dom";
import { ActionButton, BackButton, DataStatus } from "components";
import { TabItemSumberDaya } from "./Comps";

export const UbahSubKegiatan = () => {
  const { id } = useParams();
  const history = useHistory();
  const [dataProgram, setDataProgram] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
    show: false,
  });
  const getDataSingle = () => {
    setIsLoading(true);
    SubKegiatanApi.getSingle({ id_sub_kegiatan: id })
      .then(({ data }) => {
        setDataProgram(data?.data);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const formSubmitHandler = (value, { setSubmitting }) => {
    SubKegiatanApi.create(formSubmmitMapper(value))
      .then(() => {
        history.push("/transaksi/sub-kegiatan", {
          alert: {
            show: true,
            variant: "success",
            text: "Data berhasil diubah!",
          },
        });
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          text: "Data gagal disimpan !!",
          variant: "danger",
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    getDataSingle();

    return () => {
      setIsLoading(false);
    };
  }, []);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={formInitialValues(dataProgram)}
        validationSchema={formValidationSchema}
        onSubmit={formSubmitHandler}
      >
        {({ isSubmitting, handleSubmit, resetForm }) => (
          <>
            {isLoading ? (
              <DataStatus text="Memuat Data..." loading />
            ) : (
              <>
                <div className="d-flex justify-content-between mb-2">
                  <span style={{ fontSize: "14px" }}>
                    <b>Detail Data Program</b>
                  </span>
                  <BackButton size="sm" onClick={() => history.goBack()} />
                </div>
                <Card className="mb-3">
                  <Card.Body>
                    <InfoProgramSection />
                  </Card.Body>
                </Card>
                <span style={{ fontSize: "14px" }}>
                  <b>Detail Data Kegiatan</b>
                </span>
                <Card className="mb-3">
                  <Card.Body>
                    <InfoKegiatanSection />
                  </Card.Body>
                </Card>
                <span style={{ fontSize: "14px" }}>
                  <b>Detail Data Kegiatan</b>
                </span>
                <form onSubmit={handleSubmit}>
                  <Card className="mb-3">
                    <Card.Body>
                      <FormSection />
                    </Card.Body>
                  </Card>
                  <span style={{ fontSize: "14px" }}>
                    <b>List Item Sumber Daya</b>
                  </span>
                  <Card>
                    <TabItemSumberDaya />
                  </Card>
                  <span style={{ fontSize: "14px" }}>
                    <b>Summary</b>
                  </span>

                  <SummarySection />
                  <div className="d-flex justify-content-end  my-3">
                    <ActionButton
                      size="sm"
                      text="Simpan"
                      type="submit"
                      loading={isSubmitting}
                    />
                  </div>
                </form>
              </>
            )}
          </>
        )}
      </Formik>
    </>
  );
};
