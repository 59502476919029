import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { FilterModal, DatePicker, SelectSearch } from "components";
import { DateConvert } from "utilities";
import Axios from "axios";
import { ProgramApi } from "api";

export const ModalFilterProgram = ({
  show,
  setShow,
  data,
  setData,
  dropdown,
}) => {
  // STATE DATA
  const dataUnitOrganisasi = [
    { label: "Semua", value: undefined },
    ...dropdown?.unitOrganisasi,
  ];

  const dataPenanggungJawab = [
    { label: "Semua", value: undefined },
    ...dropdown?.penanggungJawab,
  ];
  const dataStatusOpname = [
    { label: "Semua", value: undefined },
    {
      label: "BELUM DIOPNAME",
      value: false,
    },
    {
      label: "SUDAH DIOPNAME",
      value: true,
    },
  ];
  const dataApproval = [
    { label: "Semua", value: undefined },
    {
      value: "APP",
      label: "APPROVED",
    },
    {
      value: "VER",
      label: "VERIFIED",
    },
    {
      value: "REV",
      label: "REVISI",
    },
    {
      value: "REJ",
      label: "REJECT",
    },
    {
      value: "PEN",
      label: "PENDING",
    },
  ];

  // FORM VALUES
  const formInitialValues = {
    tgl_program_start: data?.filter?.tgl_program_start,
    tgl_program_end: data?.filter?.tgl_program_end,
    tgl_kegiatan_start: data?.filter?.tgl_kegiatan_start,
    tgl_kegiatan_end: data?.filter?.tgl_kegiatan_end,
    tgl_sub_kegiatan_start: data?.filter?.tgl_sub_kegiatan_start,
    tgl_sub_kegiatan_end: data?.filter?.tgl_sub_kegiatan_end,
    tgl_ppa_start: data?.filter?.tgl_ppa_start,
    tgl_ppa_end: data?.filter?.tgl_ppa_end,
    status_approval_ppa: data?.filter?.status_approval_ppa,
  };
  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setData({
        filter: {
          ...values,
          active: true,
        },
        pagination: {
          ...data.pagination,
          page: 1,
        },
      });
    } else {
      setData({
        ...data,
        filter: {
          ...values,
          active: false,
        },
      });
    }

    setShow(false);
  };
  // HANDLE CHANGE
  const onTanggalChange = (dates, values, setValues, keyStart, keyEnd) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      [keyStart]: startDate,
      [keyEnd]: endDate,
    });
  };

  const onResetButtonClick = (values, setValues) => {
    setValues({
      tgl_program_start: undefined,
      tgl_program_end: undefined,
      tgl_kegiatan_start: undefined,
      tgl_kegiatan_end: undefined,
      tgl_sub_kegiatan_start: undefined,
      tgl_sub_kegiatan_end: undefined,
      tgl_ppa_start: undefined,
      tgl_ppa_end: undefined,
      status_approval_ppa: undefined,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}
    >
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(values, setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            label="Tgl. Program"
            placeholderText="Pilih tanggal program"
            startDate={
              values.tgl_program_start ? new Date(values.tgl_program_start) : ""
            }
            endDate={
              values.tgl_program_end ? new Date(values.tgl_program_end) : ""
            }
            onChange={(dates) =>
              onTanggalChange(
                dates,
                values,
                setValues,
                "tgl_program_start",
                "tgl_program_end"
              )
            }
            monthsShown={2}
          />
          <DatePicker
            selectsRange
            label="Tgl. Kegiatan"
            placeholderText="Pilih tanggal kegiatan"
            startDate={
              values.tgl_kegiatan_start
                ? new Date(values.tgl_kegiatan_start)
                : ""
            }
            endDate={
              values.tgl_kegiatan_end ? new Date(values.tgl_kegiatan_end) : ""
            }
            onChange={(dates) =>
              onTanggalChange(
                dates,
                values,
                setValues,
                "tgl_kegiatan_start",
                "tgl_kegiatan_end"
              )
            }
            monthsShown={2}
          />
          <DatePicker
            selectsRange
            label="Tgl. Sub Kegiatan"
            placeholderText="Pilih tanggal sub kegiatan"
            startDate={
              values.tgl_sub_kegiatan_start
                ? new Date(values.tgl_sub_kegiatan_start)
                : ""
            }
            endDate={
              values.tgl_sub_kegiatan_end
                ? new Date(values.tgl_sub_kegiatan_end)
                : ""
            }
            onChange={(dates) =>
              onTanggalChange(
                dates,
                values,
                setValues,
                "tgl_sub_kegiatan_start",
                "tgl_sub_kegiatan_end"
              )
            }
            monthsShown={2}
          />

          <DatePicker
            selectsRange
            label="Tgl. PPA"
            placeholderText="Pilih tanggal ppa"
            startDate={
              values.tgl_ppa_start ? new Date(values.tgl_ppa_start) : ""
            }
            endDate={values.tgl_ppa_end ? new Date(values.tgl_ppa_end) : ""}
            onChange={(dates) =>
              onTanggalChange(
                dates,
                values,
                setValues,
                "tgl_ppa_start",
                "tgl_ppa_end"
              )
            }
            monthsShown={2}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
