import Services from "../../services";

class SubKegiatanApi {
  dropdownUnitOrganisasi() {
    return Services.get("/unit_organisasi/dropdown");
  }

  dropdownKategoriAnggaran(params) {
    return Services.get("/kategori_anggaran/dropdown", { params });
  }

  dropdownItemSumberDayaBuaso(params) {
    return Services.get("/dropdown/buaso", { params });
  }

  dropdownItemSumberDaya() {
    return Services.get("/item_sumber_daya");
  }
  dropdownItemSumberDayaLainnya(params) {
    return Services.get("/item_sumber_daya", { params });
  }

  dropdownItemAset(params) {
    return Services.get("/dropdown/aset", { params });
  }

  dropdownSumberPengadaan() {
    return Services.get("/sumber_pengadaan/dropdown");
  }

  dropdownKaryawan() {
    return Services.get("/karyawan/dropdown");
  }

  dropdownJenisAnggaran() {
    return Services.get("/jenis_anggaran/dropdown");
  }

  dropdownKelompokAnggaran(params) {
    return Services.get("/kelompok_anggaran/dropdown", { params });
  }

  dropdownSubKelompok(params) {
    return Services.get("/sub_kelompok_anggaran/dropdown", { params });
  }

  dropdownGrupAset() {
    return Services.get("/dropdown/group_aset");
  }

  dropdownKategoriAset(params) {
    return Services.get("/dropdown/kategori_aset", { params });
  }

  dropdownJenisAset(params) {
    return Services.get("/dropdown/jenis_aset", { params });
  }

  getPage(params) {
    return Services.get("/sub_kegiatan/", { params });
  }

  getPageKegiatan(params) {
    return Services.get("/kegiatan/", { params });
  }

  getSingle(params) {
    return Services.get("sub_kegiatan/single/", { params });
  }

  getDetail(params) {
    return Services.get("detail", { params });
  }

  getKode(params) {
    return Services.get("/sub_kegiatan/no_baru", { params });
  }

  show(value) {
    return Services.put("/sub_kegiatan/show", value);
  }

  hide(value) {
    return Services.put("/sub_kegiatan/hide", value);
  }

  create(value) {
    return Services.post("/sub_kegiatan", value);
  }

  update(value) {
    return Services.put("/sub_kegiatan", value);
  }

  delete(value) {
    return Services.post("/sub_kegiatan/delete", value);
  }
}

export default new SubKegiatanApi();
