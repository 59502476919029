import { KelompokAnggaranApi } from "api";
import { ActionButton, Input, SelectSearch, TextArea } from "components";
import { useFormikContext } from "formik";
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

export const ModalAction = ({ setModalConfig, modalConfig, dropdown }) => {
  const [isNomorLoading, setIsNomorLoading] = useState(false);
  const {
    values,
    handleChange,
    setValues,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
    resetForm,
  } = useFormikContext();

  const getNomorHandler = () => {
    setIsNomorLoading(true);

    KelompokAnggaranApi.getKode()
      .then(({ data }) => {
        const kode = data.data;

        setValues({
          ...values,
          kodeKelompok: kode,
          keterangan: "",
          idKelompok: "",
          namaKelompok: "",
          idJenis: "",
          namaJenis: "",
        });
      })
      .catch(() => {
        setValues({
          ...values,
          kodeKelompok: "",
          keterangan: "",
          idKelompok: "",
          namaKelompok: "",
          idJenis: "",
          namaJenis: "",
        });
        window.alert("Nomor gagal dimuat");
      })
      .finally(() => {
        setIsNomorLoading(false);
      });
  };

  useEffect(() => {
    modalConfig.type === "add" && getNomorHandler();

    return () => {
      setIsNomorLoading(false);
    };
  }, []);

  return (
    <div>
      <Modal
        show={modalConfig?.show}
        onHide={() => setModalConfig({ data: {}, type: "add", show: false })}
      >
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            {modalConfig.type === "edit" ? "Ubah" : "Tambah"} Kelompok Anggaran
          </Modal.Header>
          <Modal.Body>
            <Input
              label="Kode Kelompok Anggaran"
              placeholder="Masukan kode kelompok anggaran"
              name="kodeKelompok"
              value={isNomorLoading ? "Memuat data ..." : values?.kodeKelompok}
              readOnly
            />
            <Input
              label="Nama Kelompok Anggaran"
              placeholder="Masukan nama kelompok anggaran"
              name="namaKelompok"
              value={values?.namaKelompok}
              onChange={handleChange}
              error={Boolean(errors.namaKelompok) && touched.namaKelompok}
              errorText={
                Boolean(errors.namaKelompok) &&
                touched.namaKelompok &&
                errors.namaKelompok
              }
            />

            <SelectSearch
              label="Jenis Anggaran"
              placeholder="Pilih jenis anggaran"
              option={dropdown}
              loading={isNomorLoading}
              onChange={(val) =>
                setValues({
                  ...values,
                  idJenis: val?.value,
                  namaJenis: val?.label,
                })
              }
              defaultValue={
                values?.idJenis && {
                  value: values.idJenis,
                  label: values.namaJenis,
                }
              }
              error={Boolean(errors.idJenis) && touched.idJenis}
              errorText={
                Boolean(errors.idJenis) && touched.idJenis && errors.idJenis
              }
            />
            <TextArea
              label="Keterangan"
              placeholder="Masukan keterangan"
              name="keterangan"
              rows="4"
              value={values?.keterangan}
              onChange={handleChange}
            />
          </Modal.Body>
          <Modal.Footer>
            {/* <ActionButton
              text="Batal"
              variant="outline-secondary"
              size="sm"
              onClick={() =>
                resetForm({
                  values: {
                    keterangan: "",
                    idKelompok: "",
                    namaKelompok: "",
                    idJenis: "",
                    namaJenis: "",
                  },
                })
              }
            /> */}
            <ActionButton
              text={modalConfig.type === "edit" ? "Ubah" : "Simpan"}
              type="submit"
              size="sm"
              variant={modalConfig.type === "edit" ? "success" : "primary"}
              loading={isSubmitting}
            />
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};
