import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { DatePicker, Input, SelectSearch, TextArea } from "components";
import { useFormikContext } from "formik";
import { ProgramApi } from "api";
import { DateConvert } from "utilities";

export const FormSection = ({ dropdown }) => {
  const { values, setValues, handleChange, setFieldValue } = useFormikContext();
  const [isNomorLoading, setIsNomorLoading] = useState(false);

  const getNomorHandler = (tgl) => {
    setIsNomorLoading(true);
    const tanggal = DateConvert(new Date(tgl)).default;

    ProgramApi.getKode({ tanggal })
      .then(({ data }) => {
        const no = data?.data;
        setValues({
          ...values,
          tglProgram: tanggal,
          noProgram: no,
        });
      })
      .catch(() => {
        setValues({
          ...values,
          noProgram: "",
          tglProgram: null,
        });
        window.alert("Nomor gagal dimuat");
      })
      .finally(() => {
        setIsNomorLoading(false);
      });
  };

  return (
    <Row>
      <Col lg="6">
        <DatePicker
          label="Tgl. Program"
          dateFormat="dd/MM/yyyy"
          onChange={(date) => {
            getNomorHandler(date);
          }}
          selected={values?.tglProgram ? new Date(values?.tglProgram) : null}
        />
      </Col>
      <Col lg="6">
        <Input
          label="No. Program"
          value={isNomorLoading ? "Memuat Data.." : values.noProgram}
          placeholder="Pilih tanggal terlebih dahulu"
        />
      </Col>
      <Col lg="6">
        <Input
          label="Nama Program"
          name="namaProgram"
          type="text"
          placeholder="Masukan nama program"
          onChange={handleChange}
          value={values?.namaProgram}
        />
      </Col>
      <Col lg="6">
        <SelectSearch
          key={values?.idUnitProduksi}
          label="Unit Produksi"
          placeholder="Pilih unit produksi"
          option={dropdown?.unitProduksi}
          onChange={(res) =>
            setValues({
              ...values,
              idUnitProduksi: res.value,
              namaUnitProduksi: res.label,
            })
          }
          defaultValue={
            values?.idUnitProduksi && {
              value: values.idUnitProduksi,
              label: values.namaUnitProduksi,
            }
          }
        />
      </Col>
      <Col lg="6">
        <SelectSearch
          key={values?.idUnitOrganisasi}
          label="Unit Organisasi"
          placeholder="Pilih unit organisasi"
          option={dropdown?.unitOrganisasi}
          onChange={(res) =>
            setValues({
              ...values,
              idUnitOrganisasi: res.value,
              namaUnitOrganisasi: res.label,
            })
          }
          defaultValue={
            values?.idUnitOrganisasi && {
              value: values.idUnitOrganisasi,
              label: values.namaUnitOrganisasi,
            }
          }
        />
        <DatePicker
          label="Periode Mulai"
          dateFormat="dd/MM/yyyy"
          onChange={(date) =>
            setFieldValue("periodeMulai", DateConvert(new Date(date)).default)
          }
          selected={
            values?.periodeMulai ? new Date(values?.periodeMulai) : null
          }
        />
      </Col>
      <Col lg="6">
        <SelectSearch
          key={values?.idPenanggungJawab}
          label="Penanggung Jawab"
          placeholder="Pilih penanggung jawab"
          option={dropdown?.penanggungJawab}
          onChange={(res) =>
            setValues({
              ...values,
              idPenanggungJawab: res.value,
              namaPenanggungJawab: res.label,
            })
          }
          defaultValue={
            values?.idPenanggungJawab && {
              value: values.idPenanggungJawab,
              label: values.namaPenanggungJawab,
            }
          }
        />
        <DatePicker
          label="Periode Selesai"
          dateFormat="dd/MM/yyyy"
          onChange={(date) =>
            setFieldValue("periodeSelesai", DateConvert(new Date(date)).default)
          }
          selected={
            values?.periodeSelesai ? new Date(values?.periodeSelesai) : null
          }
        />
      </Col>
      <Col lg="12">
        <TextArea
          label="Deskripsi"
          rows="4"
          placeholder="Masukan deskripsi"
          name="deskripsi"
          onChange={handleChange}
          value={values.deskripsi}
        />
      </Col>
    </Row>
  );
};
