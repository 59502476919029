import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import {
  Checkbox,
  DatePicker,
  InfoItemHorizontal,
  Input,
  RadioButton,
  RadioButtonWrapper,
  SelectSearch,
  TextArea,
} from "components";
import { useFormikContext } from "formik";
import Axios from "axios";
import { PPAApi } from "api";
import { DateConvert } from "utilities";

const InfoSection = ({ data }) => {
  return (
    <>
      <Row>
        <Col lg="6">
          <InfoItemHorizontal
            label="Tgl. PPA"
            text={data.tglPPA ? DateConvert(new Date(data.tglPPA)).detail : "-"}
          />
          <InfoItemHorizontal label="No. PPA" text={data.noPPA ?? "-"} />
          <InfoItemHorizontal
            label="Deskripsi"
            text={data.deskripsiPPA ?? "-"}
          />
        </Col>
      </Row>
    </>
  );
};

export const FormSection = ({ type }) => {
  const { values, setValues, errors, touched } = useFormikContext();
  const [isNomorLoading, setIsNomorLoading] = useState(false);
  const [dropdownPenanggungJawab, setDropdownPenanggungJawab] = useState([]);

  const getNomorHandler = (tgl) => {
    setIsNomorLoading(true);
    const tanggal = DateConvert(new Date(tgl)).default;

    PPAApi.getKode({ tanggal })
      .then(({ data }) => {
        const no = data?.data;
        setValues({
          ...values,
          PPA: {
            ...values.PPA,
            noPPA: no,
            tglPPA: tanggal,
          },
        });
      })
      .catch(() => {
        setValues({
          ...values,
          PPA: {
            ...values.PPA,
            noPPA: "",
            tglPPA: null,
          },
        });
        window.alert("Nomor gagal dimuat");
      })
      .finally(() => {
        setIsNomorLoading(false);
      });
  };

  const getDropdown = () => {
    Axios.all([PPAApi.dropdownKaryawan()]).then(
      Axios.spread((karyawan) => {
        const mappingDropdownPenanggungJawab = (data) =>
          data.map((val) => ({
            label: val.nama_karyawan,
            value: val.id_karyawan,
          }));

        setDropdownPenanggungJawab(
          mappingDropdownPenanggungJawab(karyawan?.data?.data ?? [])
        );
      })
    );
  };

  useEffect(() => {
    type !== "read" && getDropdown();

    return () => {};
  }, []);

  return type === "read" ? (
    <InfoSection data={values?.PPA} />
  ) : (
    <Row>
      <Col lg="6">
        <DatePicker
          label="Tgl. PPA"
          dateFormat="dd/MM/yyyy"
          onChange={(date) => {
            getNomorHandler(date);
          }}
          selected={values?.PPA?.tglPPA ? new Date(values?.PPA?.tglPPA) : null}
          error={Boolean(errors?.PPA?.tglPPA) && touched?.PPA?.tglPPA}
          errorText={
            Boolean(errors?.PPA?.tglPPA) &&
            touched?.PPA?.tglPPA &&
            errors?.PPA?.tglPPA
          }
        />
      </Col>
      <Col lg="6">
        <Input
          label="No. PPA"
          disabled
          value={isNomorLoading ? "Memuat Data.." : values?.PPA?.noPPA}
          placeholder="Pilih tanggal terlebih dahulu"
        />
      </Col>

      <Col lg="12">
        <TextArea
          label="Deskripsi"
          rows="4"
          placeholder="Masukan deskripsi"
          name="deskripsiPPA"
          onChange={(e) =>
            setValues({
              ...values,
              PPA: {
                ...values?.PPA,
                deskripsiPPA: e?.target?.value,
              },
            })
          }
          value={values?.PPA?.deskripsiPPA}
          error={
            Boolean(errors?.PPA?.deskripsiPPA) && touched?.PPA?.deskripsiPPA
          }
          errorText={
            Boolean(errors?.PPA?.deskripsiPPA) &&
            touched?.PPA?.deskripsiPPA &&
            errors?.PPA?.deskripsiPPA
          }
        />
      </Col>
    </Row>
  );
};
