export const formSubmmitMapper = (data) => ({
  id_sub_kegiatan: data?.subKegiatan?.idSubKegiatan,
  id_kegiatan: data?.kegiatan?.idKegiatan,
  tgl_sub_kegiatan: data?.subKegiatan?.tglSubKegiatan,
  deskripsi: data?.subKegiatan?.deskripsiSubKegiatan,
  nama_sub_kegiatan: data?.subKegiatan?.namaSubKegiatan,
  tgl_input: data?.subKegiatan?.tglInputSubKegiatan,
  periode_mulai: data?.subKegiatan?.periodeMulaiSubKegiatan,
  periode_selesai: data?.subKegiatan?.periodeSelesaiSubKegiatan,
  is_aset: data?.subKegiatan?.isAset,
  is_wip: data?.subKegiatan?.isWip,
  id_penanggung_jawab: data?.subKegiatan?.idPenanggungJawabSubKegiatan,
  material: data.subKegiatan.detailMaterial
    ? data.subKegiatan.detailMaterial.map((val) => ({
        id_item_sub_kegiatan_material: val.idItemSubKegiatan,
        qty_item_sub_kegiatan_material: val.qty,
        harga_satuan: val?.hargaSatuan,
        id_item_bahan: val?.idItemSumberDaya,
        id_sumber_pengadaan: val?.idSumberPengadaan,
        id_kategori_anggaran: val?.idKategori,
      }))
    : [],
  manusia: data.subKegiatan.detailManusia
    ? data.subKegiatan.detailManusia.map((val) => ({
        id_item_sub_kegiatan_manusia: val.idItemSubKegiatan,
        qty_item_sub_kegiatan_manusia: val.qty,
        harga_satuan: val?.hargaSatuan,
        id_item_upah: val?.idItemSumberDaya,
        id_sumber_pengadaan: val?.idSumberPengadaan,
        id_kategori_anggaran: val?.idKategori,
      }))
    : [],
  peralatan: data.subKegiatan.detailPeralatan
    ? data.subKegiatan.detailPeralatan.map((val) => ({
        id_item_sub_kegiatan_peralatan: val.idItemSubKegiatan,
        qty_item_sub_kegiatan_peralatan: val.qty,
        harga_satuan: val?.hargaSatuan,
        id_item_alat_mesin: val?.idItemSumberDaya,
        id_sumber_pengadaan: val?.idSumberPengadaan,
        id_kategori_anggaran: val?.idKategori,
      }))
    : [],
  overhead: data.subKegiatan.detailOverhead
    ? data.subKegiatan.detailOverhead.map((val) => ({
        id_item_sub_kegiatan_overhead: val.idItemSubKegiatan,
        qty_item_sub_kegiatan_overhead: val.qty,
        harga_satuan: val?.hargaSatuan,
        id_item_sumber_daya: val?.idItemSumberDaya,
        id_sumber_pengadaan: val?.idSumberPengadaan,
        id_kategori_anggaran: val?.idKategori,
      }))
    : [],
  lainnya: data.subKegiatan.detailLainnya
    ? data.subKegiatan.detailLainnya.map((val) => ({
        id_item_sub_kegiatan_lainnya: val.idItemSubKegiatan,
        qty_item_sub_kegiatan_lainnya: val.qty,
        harga_satuan: val?.hargaSatuan,
        id_item_sumber_daya: val?.idItemSumberDaya,
        id_sumber_pengadaan: val?.idSumberPengadaan,
        id_kategori_anggaran: val?.idKategori,
      }))
    : [],
});
