import {
  IoCashOutline,
  IoSpeedometerOutline,
  IoServerOutline,
  IoDocumentTextOutline,
} from "react-icons/io5";

/* 
  MENU REQUIREMENTS
    > TEXT  = REQUIRED
    > LINK  = REQUIRED
    > EXACT = OPTIONAL (TRUE/FALSE)
    > TYPE  = REQUIRED (MENU/DROPDOWN)
    > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
    > ICON  = OPTIONAL (REACT-ICONS)
*/

export default [
  {
    //Dashboard
    text: "Dashboard",
    link: "/",
    type: "menu",
    exact: true,
    icon: <IoSpeedometerOutline />,
    hak: ["SUPA"],
  },
  {
    // Master
    text: "Master",
    type: "dropdown",
    icon: <IoServerOutline />,
    hak: [
      "SUPA",
      "ANG",
      "ANG_MAS_JEN",
      "ANG_MAS_KEL",
      "ANG_MAS_SUB",
      "ANG_MAS_KAT",
      "ANG_MAS_ISD",
      "ANG_MAS_MPC",
      "ANG_MAS_SUP",
    ],
    menu: [
      {
        text: "Jenis Anggaran",
        link: "/master/jenis-anggaran",
        hak: ["SUPA", "ANG_MAS_JEN"],
      },
      {
        text: "Kelompok Anggaran",
        link: "/master/kelompok-anggaran",
        hak: ["SUPA", "ANG_MAS_KEL"],
      },
      {
        text: "Sub Kelompok Anggaran",
        link: "/master/sub-kelompok-anggaran",
        hak: ["SUPA", "ANG_MAS_SUB"],
      },
      {
        text: "Kategori Anggaran",
        link: "/master/kategori-anggaran",
        hak: ["SUPA", "ANG_MAS_KAT"],
      },
      {
        text: "Item Sumber Daya",
        link: "/master/item-sumber-daya",
        hak: ["SUPA", "ANG_MAS_ISD"],
      },
      {
        text: "Mapping Coa Item Sumber Daya",
        link: "/master/mapping-coa-item",
        hak: ["SUPA", "ANG_MAS_MPC"],
      },
      {
        text: "Sumber Pengadaan",
        link: "/master/sumber-pengadaan",
        hak: ["SUPA", "ANG_MAS_SUP"],
      },
    ],
  },
  {
    // Transaksi
    text: "Transaksi",
    type: "dropdown",
    icon: <IoCashOutline />,
    hak: [
      "SUPA",
      "ANG",
      "ANG_TRN_PRO",
      "ANG_TRN_KEG",
      "ANG_TRN_SKEG",
      "ANG_TRN_PPA",
      "ANG_TRN_REA",
    ],
    menu: [
      {
        text: "Program",
        link: "/transaksi/program",
        hak: ["SUPA", "ANG_TRN_PRO"],
      },
      {
        text: "Kegiatan",
        link: "/transaksi/kegiatan",
        hak: ["SUPA", "ANG_TRN_KEG"],
      },
      {
        text: "Sub Kegiatan",
        link: "/transaksi/sub-kegiatan",
        hak: ["SUPA", "ANG_TRN_SKEG"],
      },
      {
        text: "PPA",
        link: "/transaksi/ppa",
        hak: ["SUPA", "ANG_TRN_PPA"],
      },
      {
        text: "Realisasi PPA",
        link: "/transaksi/realisasi-ppa",
        hak: ["SUPA", "ANG_TRN_REA"],
      },
    ],
  },
  {
    // Laporan
    text: "Laporan",
    type: "dropdown",
    icon: <IoDocumentTextOutline />,
    hak: ["SUPA"],
    menu: [
      {
        text: "Comming Soon",
        link: "/laporan",
        hak: ["SUPA", "COC_MAS_KLO"],
      },
    ],
  },
];
