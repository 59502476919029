import Services from "../../services";

class SumberPengadaanApi {
  get() {
    return Services.get("/sumber_pengadaan");
  }

  getPage(params) {
    return Services.get("/sumber_pengadaan/page/", { params });
  }

  getKode() {
    return Services.get("/sumber_pengadaan/no_baru");
  }

  show(value) {
    return Services.put("/sumber_pengadaan/show", value);
  }

  hide(value) {
    return Services.put("/sumber_pengadaan/hide", value);
  }

  create(value) {
    return Services.post("/sumber_pengadaan", value);
  }

  update(value) {
    return Services.put("/sumber_pengadaan", value);
  }

  delete(value) {
    return Services.post("/sumber_pengadaan/delete", value);
  }
}

export default new SumberPengadaanApi();
